@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500&display=swap');
@import url('https://fonts.googleapis.com/icon?family=Nunito:300,400,500;600&display=swap');

[data-theme='apiz-grid-theme-base'] {
    //#region colors
    --az-secondary-foreground-color: #f8fafc;
    --az-secondary-background-color: #5b688b;
    --az-secondary-hover-background-color: #4a5573;
    --az-alert-foreground-color: #ae364b;
    --az-alert-background-color: #f6ccd0;
    --az-success-foreground-color: #1a260e;
    --az-success-background-color: #e5f7d4;
    //#endregion
    //#region global
    --az-font-family: 'Nunito', sans-serif;
    --az-font-size: 14px;
    --az-font-size-icons: 16px;
    --az-cell-padding: 0.5em;
    --az-focus-color: #2361ff;
    --az-focus-translucent-color: #2361ff18;
    --az-foreground-color: #3d3d3e;
    --az-background-color: #ffffff;
    --az-background-alt-color: #ecf1f8;
    --az-border-color: #e0e0e0;
    --az-border-hover-color: #8d959e;
    --az-border-focus-color: var(--az-focus-color);
    --az-border-radius: 5px;
    --az-box-shadow: 0px 0px 10px 0px #232e3866;
    --az-transition: 0.2s cubic-bezier(0.4, 0, 0.2, 1);
    //#endregion
    //#region scrollbar
    --az-scrollbar-thumb-color: #e0e0e0;
    --az-scrollbar-background-color: #f8fafc;
    //#endregion
    //#region row
    --az-row-height: 28px;
    --az-row-border-color: #e0e0e0;
    // --az-row-background-color: #f8fafc;
    --az-row-foreground-color: var(--az-foreground-color);
    --az-odd-row-background-color: #f8fafc;
    --az-row-header-font-family: 'Roboto', sans-serif;
    --az-row-header-background-color: #ffffff;
    --az-row-header-foreground-color: #5b688b;
    --az-row-hover-background-color: #2361ff18;
    --az-row-selected-background-color: #e0e0e0;
    --az-row-grouping-icon-foreground-color: #5b688b;
    //#endregion
    //#region checkbox
    --az-checkbox-border-radius: 0.41667em;
    --az-checkbox-border-color: #8d959e;
    --az-checkbox-foreground-color: transparent;
    --az-checkbox-checked-border-color: var(--az-focus-color);
    --az-checkbox-checked-foreground-color: var(--az-focus-color);
    //#endregion
    //#region select
    --az-select-foreground-color: #5b688b;
    --az-select-options-border-color: #e0e0e0;
    //#endregion
    //#region autocomplete
    --az-autocomplete-border-color: #ecf1f8;
    --az-autocomplete-list-border-color: #8d959e;
    --az-autocomplete-list-border-radius: 5px;
    //#endregion
    //#region applied
    --az-applied-item-background-color: #ecf1f8;
    --az-applied-item-hover-background-color: #e0e0e0;
    //#endregion
    //#region group
    --az-group-label-foreground-color: var(--az-focus-color);
    --az-group-icon-foreground-color: var(--az-alert-foreground-color);
    //#endregion
    //#region sort
    --az-sort-label-foreground-color: var(--az-focus-color);
    --az-sort-icon-foreground-color: var(--az-alert-foreground-color);
    //#endregion
    //#region input
    --az-input-font-size: 1em;
    --az-input-padding-block: 0.25em;
    --az-input-padding-inline: 0.5em;
    --az-input-border-radius: var(--az-border-radius);
    --az-input-foreground: var(--az-foreground-color);
    --az-input-hover-foreground-color: var(--az-foreground-color);
    --az-input-focus-foreground-color: var(--az-foreground-color);
    --az-input-disabled-foreground-color: var(--az-foreground-color);
    --az-input-placeholder-foreground-color: var(--az-foreground-color);
    --az-input-placeholder-hover-foreground-color: var(--az-foreground-color);
    --az-input-text-border-color: var(--az-border-color);
    --az-input-hover-border-color: var(--az-border-hover-color);
    --az-input-focus-border-color: var(--az-border-focus-color);
    --az-input-text-background-color: var(--az-background-alt-color);
    --az-input-hover-background-color: var(--az-row-hover-background-color);
    --az-input-focus-background-color: var(--az-focus-translucent-color);
    //#endregion
    //#region Button
    --az-button-secondary-background-color: var(--az-secondary-background-color);
    --az-button-secondary-hover-background-color: var(--az-secondary-hover-background-color);
    --az-button-secondary-foreground-color: var(--az-secondary-foreground-color);

    --az-button-alert-background-color: var(--az-alert-background-color);
    --az-button-alert-hover-background-color: var(--az-alert-foreground-color);
    --az-button-alert-foreground-color: var(--az-alert-foreground-color);
    --az-button-alert-hover-foreground-color: #ffffff;
    //#endregion

    //#region loading overlay
    --az-loading-overlay-background-color: rgb(220 219 219 / 30%);
    --az-loading-overlay-foreground-color: var(--az-foreground-color);
    --az-loading-overlay-label-border-color: var(--az-foreground-color);
}
