@font-face {
  font-family: 'apiz-grid-icons';
  src:  url('fonts/apiz-grid-icons.eot?8sxrns');
  src:  url('fonts/apiz-grid-icons.eot?8sxrns#iefix') format('embedded-opentype'),
    url('fonts/apiz-grid-icons.ttf?8sxrns') format('truetype'),
    url('fonts/apiz-grid-icons.woff?8sxrns') format('woff'),
    url('fonts/apiz-grid-icons.svg?8sxrns#apiz-grid-icons') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="az-"], [class*=" az-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'apiz-grid-icons' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.az-pin:before {
  content: "\e919";
}
.az-chevron-up:before {
  content: "\e918";
}
.az-drag-and-drop:before {
  content: "\e900";
}
.az-total:before {
  content: "\e902";
}
.az-aggregation:before {
  content: "\e903";
}
.az-drag-cursor:before {
  content: "\e904";
}
.az-calendar:before {
  content: "\e905";
}
.az-trash:before {
  content: "\e906";
}
.az-lens:before {
  content: "\e907";
}
.az-minus:before {
  content: "\e908";
}
.az-plus:before {
  content: "\e909";
}
.az-group:before {
  content: "\e90a";
}
.az-chevron-down:before {
  content: "\e90c";
}
.az-settings:before {
  content: "\e90d";
}
.az-manage:before {
  content: "\e90e";
}
.az-forward-step:before {
  content: "\e90f";
}
.az-backward-step:before {
  content: "\e910";
}
.az-chevron-left:before {
  content: "\e911";
}
.az-chevron-right:before {
  content: "\e912";
}
.az-xmark:before {
  content: "\e913";
}
.az-sort:before {
  content: "\e914";
}
.az-sort-asc:before {
  content: "\e901";
}
.az-sort-desc:before {
  content: "\e90b";
}
.az-filter:before {
  content: "\e915";
}
.az-check:before {
  content: "\e916";
}
.az-uncheck:before {
  content: "\e917";
}
