@import './icons/style.css';

// We need to do this because of angular encapsulation
.apiz-grid-applied-item .apiz-grid-applied-item-text-accent {
    color: var(--az-applied-item-text-accent-foreground-color, var(--az-focus-color));
    font-weight: var(--az-applied-item-text-accent-font-weight, 600);
}

/* ===== Scrollbar CSS ===== */
/* Firefox */
* {
    scrollbar-width: thin;
    scrollbar-color: var(--az-scrollbar-thumb-color) var(--az-scrollbar-background-color);
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
    width: var(--az-scrollbar-thumb-width, 8px);
}

*::-webkit-scrollbar-track {
    background: var(--az-scrollbar-background-color);
}

*::-webkit-scrollbar-thumb {
    background-color: var(--az-scrollbar-thumb-color);
    border-radius: 50px;
    border: none;
}

[class^='az-'],
[class*=' az-'] {
    line-height: normal;
}
