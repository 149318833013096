:root {
  /***** 
   * Theming definitions 
   *****/

  /** fonts **/
  --font-family: 'suisse_intlregular', Arial, Helvetica, sans-serif;
  --font-family-alt: 'suisse_intlregular', Arial, Helvetica, sans-serif;
  --font-family-icon: 'icomoon', sans-serif;
  --font-size: 12px;

  /** global styles **/
  --global-shadow-box: 0px 0px 10px 0px #00000066;
  --global-border-radius: 5px;
  --global-padding: 5px;
  /* scrollbar */
  --global-scrollbar-bg-color: initial;
  --global-scrollbar-bg-color-thumb: var(--global-light-color-1);
  --global-scrollbar-br-thumb: 2em;
  --global-scrollbar-wd-track: 5px;
  --global-scrollbar-bg-color-track: var(--global-light-color-3);
  --global-scrollbar-wd: 0.75em;
  --global-scrollbar-wd-firefox: thin;

  /** default colors **/
  /* background */
  --global-bg-color-1: #ededed;
  --global-border-bg-color-1: #c0bdbd;
  /* text  */
  --global-text-color-1: #0e1b23;
  /* focus */
  --global-focus-color-1: #00a0d9;
  --global-focus-color-1-translucid: #009fd926;
  /* highlight */
  --global-highlight-color-1: #007ad9;
  /* icons */
  --global-icon-ft-color-1: #243d4e;
  /* label */
  --global-label-ft-color-1: #57797b;
  /* light */
  --global-light-color-1: #cecece;
  --global-light-color-2: #ffffff;
  --global-light-color-3: #f5f9fa;
  /* dark */
  --global-dark-color-1: #969696;
  --global-dark-color-2: #000000;
  --global-dark-color-3: #333333;
  /* primary */
  --primary-bg-color: #e65400;
  --primary-bd-color: #e65400;
  --primary-ft-color: #e65400;
  --primary-ft-color-alt: var(--global-light-color-2);
  --primary-bg-color-hover: #d44e00;
  --primary-bd-color-hover: #d44e00;
  --primary-ft-color-hover: #d44e00;
  --primary-ft-color-alt-hover: var(--global-light-color-2);
  --primary-bg-color-focus: #d44e00;
  --primary-bd-color-focus: #d44e00;
  --primary-ft-color-focus: #d44e00;
  --primary-ft-color-alt-focus: var(--global-light-color-2);
  --primary-bd-color-active: #c24700;
  --primary-bg-color-active: #c24700;
  --primary-ft-color-active: #c24700;
  --primary-ft-color-alt-active: var(--global-light-color-2);
  /* secondary */
  --secondary-bg-color: #5f8289;
  --secondary-bd-color: #5f8289;
  --secondary-ft-color: #5f8289;
  --secondary-ft-color-alt: var(--global-light-color-2);
  --secondary-bg-color-hover: #4a686e;
  --secondary-bd-color-hover: #4a686e;
  --secondary-ft-color-hover: #4a686e;
  --secondary-ft-color-alt-hover: var(--global-light-color-2);
  --secondary-bg-color-focus: #4a686e;
  --secondary-bd-color-focus: #4a686e;
  --secondary-ft-color-focus: #4a686e;
  --secondary-ft-color-alt-focus: var(--global-light-color-2);
  --secondary-bg-color-active: #446066;
  --secondary-bd-color-active: #446066;
  --secondary-ft-color-active: #446066;
  --secondary-ft-color-alt-active: var(--global-light-color-2);
  /* alert */
  --alert-bg-color: #cd1400;
  --alert-bd-color: #cd1400;
  --alert-ft-color: #cd1400;
  --alert-ft-color-alt: var(--global-light-color-2);
  --alert-bg-color-hover: #ad1201;
  --alert-bd-color-hover: #ad1201;
  --alert-ft-color-hover: #ad1201;
  --alert-ft-color-alt-hover: var(--global-light-color-2);
  --alert-bg-color-focus: #ad1201;
  --alert-bd-color-focus: #ad1201;
  --alert-ft-color-focus: #ad1201;
  --alert-ft-color-alt-focus: var(--global-light-color-2);
  --alert-bg-color-active: #9b0015;
  --alert-bd-color-active: #9b0015;
  --alert-ft-color-active: #9b0015;
  --alert-ft-color-alt-active: var(--global-light-color-2);
  /* success */
  --success-bg-color: #9bfe86;
  --success-bd-color: #9bfe86;
  --success-ft-color: #9bfe86;
  --success-ft-color-alt: var(--global-dark-color-2);
  --success-bg-color-hover: #79c969;
  --success-bd-color-hover: #79c969;
  --success-ft-color-hover: #79c969;
  --success-ft-color-alt-hover: var(--global-dark-color-2);
  --success-bg-color-focus: #79c969;
  --success-bd-color-focus: #79c969;
  --success-ft-color-focus: #79c969;
  --success-ft-color-alt-focus: var(--global-dark-color-2);
  --success-bg-color-active: #63a856;
  --success-bd-color-active: #63a856;
  --success-ft-color-active: #63a856;
  --success-ft-color-alt-active: var(--global-dark-color-2);
  /* info */
  --info-bg-color: #5acad1;
  --info-bd-color: #5acad1;
  --info-ft-color: #5acad1;
  --info-ft-color-alt: var(--global-dark-color-2);
  --info-bg-color-hover: #4db2b9;
  --info-bd-color-hover: #4db2b9;
  --info-ft-color-hover: #4db2b9;
  --info-ft-color-alt-hover: var(--global-dark-color-2);
  --info-bg-color-focus: #4db2b9;
  --info-bd-color-focus: #4db2b9;
  --info-ft-color-focus: #4db2b9;
  --info-ft-color-alt-focus: var(--global-dark-color-2);
  --info-bg-color-active: #3f969c;
  --info-bd-color-active: #3f969c;
  --info-ft-color-active: #3f969c;
  --info-ft-color-alt-active: var(--global-dark-color-2);
  /* warning */
  --warning-bg-color: #ffc240;
  --warning-bd-color: #ffc240;
  --warning-ft-color: #ffc240;
  --warning-ft-color-alt: var(--global-light-color-2);
  --warning-bg-color-hover: #d3a136;
  --warning-bd-color-hover: #d3a136;
  --warning-ft-color-hover: #d3a136;
  --warning-ft-color-alt-hover: var(--global-light-color-2);
  --warning-bg-color-focus: #d3a136;
  --warning-bd-color-focus: #d3a136;
  --warning-ft-color-focus: #d3a136;
  --warning-ft-color-alt-focus: var(--global-light-color-2);
  --warning-bg-color-active: #b4892d;
  --warning-bd-color-active: #b4892d;
  --warning-ft-color-active: #b4892d;
  --warning-ft-color-alt-active: var(--global-light-color-2);

  /** general styles **/
  /* custom/loading progress bar */
  --progress-bar-bd-color: var(--secondary-bd-color);
  --progress-bar-bg-color-indeterminate: var(--primary-bg-color);
  --progress-bar-bg-color-determinate: var(--success-bg-color);
  /* loader */
  --loader-spinner-bg-color: var(--global-light-color-2);
  --loader-spinner-ft-color: var(--global-text-color-1);
  --loader-spinner-br: 50%;
  --loader-spinner-bs: 0px 0px 10px 0px #0000004d;
  /* icons */
  --icon-ft-color-excel: #1d6f42;
  --icon-ft-color-word: #003f72;
  --icon-ft-color-html: #e34c26;
  --icon-ft-color-pdf: #ff0000;
  --pi-pi-times-ft-color-hover: var(--alert-ft-color);
  --fas-fa-sm-fa-times: var(--primary-ft-color);
  /* view splitted */
  --view-splitted-bs-top-left: inset -3px 0px 3px 0px #0000000d;
  --view-splitted-bs-top-right: inset 3px 0px 3px 0px #0000000d;
  --view-splitted-bs-bottom: inset 0px 5px 5px 0px #0000000d;
  --view-splitted-bs-bottom-left: inset -3px 3px 3px 0px #0000000d;
  --view-splitted-bs-bottom-right: inset 3px 3px 3px 0px #0000000d;
  /* autres */
  --ui-shadow-bs: 0 1px 3px 0 #0000004d;
  --link-ft-color: var(--global-text-color-1);
  --p-toolbar-br-ui-corner-all: 0;
  --p-context-menu-bg-color: var(--global-light-color-2);
  --p-context-menu-ft-color-menu-item-link: var(--global-text-color-1);
  --p-disabled-bg-color: var(--input-bd-color-disabled);
  --p-disabled-bd-color: var(--input-bg-color-disabled);
  --p-buttonset-p-button-br-small: 3px;
  --help-ft-color: var(--info-ft-color);
  --p-button-bg-color-icon-only-table: var(--primary-bg-color);
  --p-button-bg-color-icon-only-table-icon-left-icon-table: var(--global-light-color-2);
  --iu-field-bg-color: transparent;
  --iu-field-bd-color: #68727b80;
  --p-menuitem-contextmenu-bg-color-hover: #00000021;

  /** inner window **/
  --window-bg-color: var(--global-light-color-2);
  --window-margin: var(--window-margin-value) var(--window-margin-value) 0 var(--window-margin-value);
  --window-margin-value: 15px;

  /** input **/
  --input-bg-color: #ebf0f0;
  --input-bg-color-hover: #d1e5e5;
  --input-bg-color-disabled: var(--global-light-color-2);
  --input-bd-color: #ebf0f0;
  --input-bd-color-hover: #aaaaaa;
  --input-bd-color-focus: var(--global-focus-color-1);
  --input-bd-color-disabled: #ebf0f0;
  --input-ft-color: var(--global-text-color-1);
  --input-ft-color-disabled: var(--global-dark-color-1);
  --input-br: var(--global-border-radius);
  --input-bg-color-icon: var(--input-bg-color);
  --input-bg-color-icon-hover: var(--input-bg-color-hover);
  --input-bg-color-icon-disabled: var(--input-bg-color-disabled);
  --input-bd-color-icon: var(--input-bd-color);
  --input-bd-color-icon-hover: var(--input-bd-color-hover);
  --input-bd-color-icon-focus: var(--input-bd-color-focus);
  --input-bd-color-icon-disabled: var(--input-bd-color-disabled);
  --input-ft-color-icon: var(--input-ft-color);
  --input-ft-color-icon-hover: var(--global-focus-color-1);
  --input-ft-color-icon-disabled: var(--input-ft-color-disabled);
  --input-br-icon: var(--global-border-radius);
  /* mandatory field */
  --empty-mandatory-bg-color: var(--input-bg-color);
  --empty-mandatory-bg-color-hover: var(--input-bg-color-hover);
  --empty-mandatory-bd-color: var(--alert-bd-color);
  --empty-mandatory-bd-color-hover: var(--alert-bd-color-hover);
  --empty-mandatory-ft-color: var(--input-ft-color);
  --empty-mandatory-ft-color-hover: var(--input-ft-color);
  --filled-mandatory-bg-color: var(--input-bg-color);
  --filled-mandatory-bg-color-hover: var(--input-bg-color-hover);
  --filled-mandatory-bd-color: var(--input-bd-color);
  --filled-mandatory-bd-color-hover: var(--input-bd-color-hover);
  --filled-mandatory-ft-color: var(--input-ft-color);
  --filled-mandatory-ft-color-hover: var(--input-ft-color);
  /* input autofill */
  --input-autofill-ft-color: var(--input-ft-color);
  /* need lookup */
  --input-ft-color-data-conflict: var(--global-text-color-1);
  --input-bd-color-data-conflict: var(--alert-bd-color);
  --input-ft-color-data-conflict-icon: var(--alert-ft-color);
  /* input product attributes ui */
  --input-product-attributes-ui-bg-color-panel: var(--global-light-color-2);
  --input-product-attributes-ui-bd-color-panel: #00000026;
  --input-product-attributes-ui-bs-panel: 0 6px 12px #0000002d;
  /* prime calendar */
  --prime-calendar-ft-color-today-suggestions-item: var(--global-text-color-1);
  --prime-calendar-ft-color-today-suggestions-item-highlight: var(--autocomplete-item-highlight-bg-color);
  /* prime datepicker */
  --prime-datepicker-bg-color: var(--global-light-color-2);
  --prime-datepicker-bd-color: var(--global-light-color-1);
  --prime-datepicker-bs: 0 3px 6px 0 #00000029;
  --prime-datepicker-bg-color-link-hover: #eaeaea;
  --prime-datepicker-bg-color-header: var(--global-light-color-2);
  --prime-datepicker-ft-color-header: var(--global-text-color-1);
  --prime-datepicker-br-header: 0;
  --prime-datepicker-ft-color-icon: var(--global-icon-ft-color-1);
  --prime-datepicker-ft-color-icon-hover: var(--primary-ft-color);
  --prime-datepicker-ft-color-icon-focus: var(--primary-ft-color);
  --prime-datepicker-bg-color-timeonly: var(--global-light-color-2);
  --prime-datepicker-bd-color-multpile-month-group: #d8dae2;
  --prime-datepicker-ft-color-table-th: var(--global-dark-color-1);
  --prime-datepicker-ft-color-table-td: var(--global-text-color-1);
  --prime-datepicker-br-table-td: 3px;
  --prime-datepicker-bg-color-table-td-link-highlight: #d0d0d0;
  --prime-datepicker-ft-color-table-td-link-highlight: var(--global-text-color-1);
  --prime-datepicker-bg-color-table-td-today-link: var(--primary-bg-color);
  --prime-datepicker-ft-color-table-td-today-link: var(--global-light-color-2);
  --prime-datepicker-ft-color-accordion-header-icon: var(--global-icon-ft-color-1);
  --prime-datepicker-ft-color-accordion-header-title: var(--global-text-color-1);
  --prime-datepicker-bd-color-buttonbar: #d8dae2;
  --prime-datepicker-bd-color-timepicker: #d8dae2;
  --prime-datepicker-ft-color-timepicker: var(--global-text-color-1);
  --prime-datepicker-ft-color-timepicker-icon: var(--global-text-color-1);
  --prime-datepicker-ft-color-timepicker-icon-hover: var(--primary-ft-color);
  --prime-datepicker-ft-color-monthpicker-month: var(--global-text-color-1);
  --prime-datepicker-bg-color-monthpicker-month-highlight: var(--global-focus-color-1);
  --prime-datepicker-ft-color-monthpicker-month-highlight: var(--global-light-color-2);

  /** buttons **/
  --button-bg-color: var(--global-light-color-2);
  --button-bg-color-hover: #bebebe;
  --button-bg-color-active: #bebebe;
  --button-bg-color-inactive: #c5c5c5;
  --button-bg-color-focus: #bebebe;
  --button-bd-color: var(--global-light-color-2);
  --button-bd-color-hover: #bebebe;
  --button-bd-color-active: #bebebe;
  --button-bd-color-inactive: #c5c5c5;
  --button-bd-color-focus: #bebebe;
  --button-ft-color: var(--global-dark-color-2);
  --button-ft-color-hover: var(--global-dark-color-3);
  --button-ft-color-active: var(--global-dark-color-3);
  --button-ft-color-inactive: #00000080;
  --button-ft-color-focus: var(--global-dark-color-3);
  --button-br: var(--global-border-radius);

  /** accordeon **/
  --accordion-bg-color-header: #bcd5d6;
  --accordion-bg-color-header-hover: #add4d6;
  --accordion-bg-color-header-highlighted: var(--secondary-bg-color);
  --accordion-bg-color-header-highlighted-hover: var(--secondary-bg-color-hover);
  --accordion-bd-color-header: #bcd5d6;
  --accordion-bd-color-header-hover: #add4d6;
  --accordion-bd-color-header-highlighted: #49707e;
  --accordion-ft-color-header: var(--global-dark-color-2);
  --accordion-ft-color-header-hover: var(--global-dark-color-3);
  --accordion-ft-color-header-highlighted: var(--global-light-color-2);
  --accordion-ft-color-header-icon: var(--accordion-ft-color-header);
  --accordion-ft-color-header-icon-hover: var(--accordion-ft-color-header-hover);
  --accordion-ft-color-header-icon-highlighted: var(--accordion-ft-color-header-highlighted);
  --accordion-bg-color-content: var(--global-light-color-2);
  --accordion-bd-color-content: var(--global-light-color-1);
  --accordion-ft-color-content: var(--global-text-color-1);
  /* accordion mandatory */
  --accordion-bd-color-header-mandatory: var(--empty-mandatory-bd-color);
  --accordion-ft-color-header-icon-mandatory: var(--empty-mandatory-ft-color);
  --accordion-ft-color-header-mandatory: var(--empty-mandatory-ft-color);

  /** operation management **/
  --operation-management-margin-spacing: 5px;

  /** universal filter **/
  --universal-filter-br: var(--global-border-radius);
  --universal-filter-bg-color: var(--global-light-color-2);
  --universal-filter-bg-color-toolbar: var(--global-light-color-2);
  --universal-filter-bg-color-toolbar-hover: var(--global-light-color-2);
  --universal-filter-bg-color-toolbar-hover-focus: var(--global-bg-color-1);
  --universal-filter-bd-color-toolbar: var(--global-dark-color-1);
  --universal-filter-bd-color-toolbar-hover-focus: var(--global-dark-color-1);
  --universal-filter-ft-color-toolbar: var(--global-text-color-1);
  --universal-filter-bg-color-toolbar-button: var(--global-light-color-2);
  --universal-filter-bg-color-toolbar-button-hover: var(--global-bg-color-1);
  --universal-filter-bd-color-toolbar-button: var(--global-dark-color-1);
  --universal-filter-bd-color-toolbar-button-focus: var(--global-focus-color-1);
  --universal-filter-ft-color-toolbar-button: var(--button-ft-color);
  --universal-filter-ft-color-toolbar-button-hover: var(--button-ft-color-hover);
  --universal-filter-br-toolbar-button: var(--universal-filter-br);
  --universal-filter-br-button: var(--universal-filter-br);
  --universal-filter-bg-color-button-hover: var(--global-bg-color-1);
  --universal-filter-ft-color-button-hover: var(--global-text-color-1);
  --universal-filter-bd-color-button-add: var(--global-bg-color-1);
  --universal-filter-bg-color-chips-hover: var(--global-bg-color-1);
  --universal-filter-bd-color-chips-hover: var(--global-light-color-1);
  --universal-filter-bg-color-chips-focus: var(--global-bg-color-1);
  --universal-filter-bd-color-chips-focus: var(--global-focus-color-1);
  --universal-filter-br-chips: 3px;
  --universal-filter-bg-color-panel: var(--global-light-color-2);
  --universal-filter-br-panel: var(--universal-filter-br);
  --universal-filter-bs-panel: 0px 0px 9px 0px #0000004d;
  --universal-filter-bd-color-group-list: var(--global-bg-color-1);
  --universal-filter-bs-group-list: 0px 0px 5px 3px #0000004d;
  --universal-filter-bg-color-input: var(--input-bg-color);
  --universal-filter-bd-color-input: var(--input-bd-color);
  --universal-filter-bd-color-input-icon: var(--global-focus-color-1);
  --universal-filter-ft-color-input-icon: var(--global-text-color-1);
  --universal-filter-bd-color-autocomplete-icon: var(--input-bd-color);
  --universal-filter-ft-color-autocomplete-icon: var(--global-text-color-1);
  --universal-filter-bg-color-input-chips: transparent;
  --universal-filter-bd-color-drag-drop-icon: #ccc;
  --universal-filter-bg-color-drag-drop-preview: var(--global-bg-color-1);
  --universal-filter-br-drag-drop-preview: var(--global-border-radius);
  --universal-filter-bs-drag-drop-preview: var(--global-shadow-box);
  --universal-filter-pd-drag-drop-preview: var(--global-padding);
  --universal-filter-bg-color-drag-drop-placeholder: var(--global-bg-color-1);
  --universal-filter-bd-color-drag-drop-placeholder: var(--global-dark-color-1);

  /** usearch **/
  --usearch-br: var(--global-border-radius);
  --usearch-bd-color-input-icons: var(--input-bd-color);
  --usearch-ft-color-input-icon: var(--global-text-color-1);
  --usearch-br-input-icons: var(--usearch-br);
  --usearch-br-input-icon-folder-search: 0;
  --usearch-bg-color-input-icon-hover: var(--input-bg-color-icon-hover);
  --usearch-bg-color-input-icon-active-highlighted: #b0e6e6;
  --usearch-bd-color-input-field-hover-input-icons: var(--global-dark-color-1);
  --usearch-bd-color-input-field-focus-within-input-icons: var(--global-focus-color-1);
  --usearch-br-input-field: 0;
  --usearch-bg-color-categories-list-item: #cef2ff;
  --usearch-bg-color-categories-list-item-hover: #aae2f7;
  --usearch-ft-color-categories-list-item-active: var(--secondary-ft-color-alt);
  --usearch-bg-color-categories-list-item-active: var(--secondary-bg-color);
  --usearch-bg-color-categories-list-item-active-hover: var(--secondary-bg-color-hover);
  --usearch-br-categories-list-item: var(--usearch-br);
  --usearch-ft-color-no-results-title: var(--alert-ft-color);
  --usearch-bd-color-no-results-title: var(--global-light-color-1);
  --usearch-ft-color-best-results-title: var(--primary-ft-color);
  --usearch-bd-color-best-results-list: var(--global-light-color-1);
  --usearch-ft-color-best-results-list-item: var(--secondary-ft-color);
  --usearch-ft-color-best-results-list-item-name: var(--global-text-color-1);
  --usearch-ft-color-other-results-list-link-item: var(--secondary-ft-color);
  --usearch-ft-color-other-results-list-link-item-hover: var(--secondary-ft-color-hover);
  --usearch-ft-color-other-results-list-link-item-icon: var(--global-text-color-1);
  --usearch-bd-color-search-other-results-grid-col: var(--global-light-color-1);
  --usearch-bd-small-color-search-other-results-grid-col: var(--global-light-color-1);
  --usearch-bd-medium-color-search-other-results-grid-col: var(--global-light-color-1);
  --usearch-bg-color-search-other-results-category-selected-list-item: var(--global-light-color-1);
  --usearch-bd-color-search-other-results-category-selected-list-item: var(--global-light-color-1);
  --usearch-bg-color-close-btn: #ebf0f0;
  --usearch-bd-color-close-btn: transparent;
  --usearch-bd-color-close-btn-hover: var(--global-dark-color-1);
  --usearch-ft-color-close-btn: var(--global-dark-color-1);
  --usearch-br-close-btn: var(--usearch-br);
  --usearch-ft-color-show-more: var(--secondary-ft-color);
  --usearch-ft-color-show-more-hover: #4db2b9;

  /** blade ui **/
  --blade-br: var(--global-border-radius);
  --blade-bd-color-grid-collapsed: var(--global-bg-color-1);
  --blade-bg-color-content-undefined: var(--global-light-color-2);
  --blade-ui-bd-color-iu-edit-view-ui-iupics-blade-content: var(--global-dark-color-1);
  --blade-ui-br-breadCrumb: var(--global-border-radius);
  --blade-ui-ft-color-close: var(--global-text-color-1);

  /** menu ui **/
  --menu-bd-color-list: var(--global-light-color-1);
  --menu-bg-color-list-scrollbar: var(--global-light-color-2);
  --menu-bd-color-category: var(--global-light-color-1);
  --menu-bd-color-category-item: var(--global-light-color-1);
  --menu-ft-color-category-item: var(--global-text-color-1);
  --menu-bg-color-category-hover: #ff41001a;
  --menu-br-category: var(--global-border-radius);
  --menu-ft-color-category-item-hover: var(--global-dark-color-2);
  --menu-ft-color-category-item-active: var(--primary-ft-color);
  --menu-ft-color-category-item-selected: #faebd7;
  --menu-ft-color-category-item-active-icon-next: var(--primary-ft-color);
  --menu-bg-color-category-group: var(--global-light-color-2);
  --menu-bd-color-category-group: var(--global-light-color-1);
  --menu-ft-color-category-group: var(--primary-ft-color);
  --menu-br-category-group: 0;
  --menu-bg-color-category-group-hover: var(--global-bg-color-1);
  --menu-bd-color-category-group-hover: var(--global-bg-color-1);
  --menu-ft-color-category-group-hover: var(--global-text-color-1);
  --menu-ft-color-category-group-icon: var(--global-icon-ft-color-1);
  --menu-ft-color-category-group-icon-hover: var(--primary-ft-color);
  --menu-bg-small-color-category-group: var(--global-light-color-2);
  --menu-bd-small-color-category-group: var(--global-light-color-1);
  --menu-ft-small-color-category-group: var(--global-text-color-1);
  --menu-bg-small-color-category-group-active: var(--global-light-color-2);
  --menu-bd-small-color-category-group-active: var(--primary-bd-color);
  --menu-ft-small-color-category-group-active: var(--primary-ft-color);
  --menu-bg-small-color-category-group-icon-active: transparent;
  --menu-bg-color-search: var(--global-bg-color-1);
  --menu-bd-color-search: var(--global-light-color-1);
  --menu-bg-color-search-input: var(--global-light-color-2);
  --menu-ft-color-icon: var(--global-text-color-1);
  --menu-ft-color-icon-window: var(--menu-ft-color-icon);
  --menu-ft-color-icon-process: var(--menu-ft-color-icon);
  --menu-ft-color-icon-report: var(--menu-ft-color-icon);
  --menu-ft-color-icon-form: var(--menu-ft-color-icon);
  --menu-ft-color-icon-workflow: var(--menu-ft-color-icon);
  --menu-ft-color-icon-label: var(--global-text-color-1);
  --menu-ft-small-color-icon: var(--global-icon-ft-color-1);
  --menu-ft-color-icon-smartphone: var(--menu-ft-color-icon);
  --menu-ft-color-item-icon: var(--global-text-color-1);
  --menu-bg-color-item-selected: #ff400033;

  /** ghost **/
  --ghost-bg-color: #dfdfdf;
  --ghost-bg-color-anime: #f1f1f1f1;
  --ghost-ft-color: #00000000;
  --ghost-elt-br: 50%;
  --ghost-br: var(--global-border-radius);

  /** menu top **/
  --menu-top-height-navbar: 38px;
  --menu-top-outer-radius: var(--global-border-radius);
  --menu-top-bg-color-p-toolbar: var(--global-light-color-2);
  --menu-top-bd-color-p-toolbar: var(--global-light-color-1);
  --menu-top-bg-color-wd-menu-top-show-p-button: var(--global-bg-color-1);
  --menu-top-bg-color-wd-menu-top-show-p-button-hover: var(--button-bg-color-hover);
  --menu-top-bd-color-wd-menu-top-show-p-button: var(--global-bg-color-1);
  --menu-top-bd-color-wd-menu-top-show-p-button-hover: var(--button-bd-color-hover);
  --menu-top-ft-color-wd-menu-top-show-p-button: var(--global-text-color-1);
  --menu-top-ft-color-wd-menu-top-show-p-button-hover: var(--button-ft-color-hover);
  --menu-top-br-wd-menu-top-show-p-button-rounded: var(--global-border-radius);
  --menu-top-bs-wd-menu-top-show-p-button-raised: 0 2px 3px 0 #00000026;
  --menu-top-br-wd-menu-top-show-notif-button-span-pin: var(--global-border-radius);
  --menu-top-bg-color-wd-menu-top-show-notif-button-span-pin: var(--primary-bg-color);
  --menu-top-ft-color-toolbar-separator: #848484;
  --menu-top-br-button-profil-menu-top: 50%;
  --menu-top-ft-color-button-profil-menu-top-span: var(--global-light-color-2);
  --menu-top-bg-color-tabs-bar-more-active-and-hover-list: var(--global-light-color-2);
  --menu-top-bg-color-tabs-bar-more-active-and-hover-list-item-list: var(--global-light-color-2);
  --menu-top-ft-color-tabs-bar-more-link: var(--global-text-color-1);
  --menu-top-ft-color-tabs-bar-more-link-hover: var(--global-text-color-1);
  --menu-top-bs-tabs-bar-more: var(--global-shadow-box);
  --menu-top-br-tabs-bar-more: 4px;
  --menu-top-ft-color-sidebar-close: var(--global-dark-color-1);

  /** dashboard **/
  --dashboard-bg-img: url('/assets/iupics/img/logo-apiz.white.svg');
  --dashboard-bg-img-size: 25%;
  --dashboard-bg-img-opacity: 50%;
  --dashboard-bg-img-position: center;
  --dashboard-bg-img-attachment: fixed;
  --dashboard-bg-img-repeat: no-repeat;
  --dashboad-ft-color: var(--global-text-color-1);

  /** show dashboard button **/
  --dashboard-button-bg-color: var(--primary-bg-color);
  --dashboard-button-bd-color: var(--primary-bd-color);
  --dashboard-button-ft-color: var(--primary-ft-color-alt);
  --dashboard-button-bg-color-hover: var(--primary-bg-color-hover);
  --dashboard-button-bd-color-hover: var(--primary-bd-color-hover);
  --dashboard-button-ft-color-hover: var(--primary-ft-color-alt-hover);
  --dashboard-button-br: var(--global-border-radius);
  --dashboard-button-bg-color-before-after: transparent;
  --dashboard-button-bs-before-after: var(--primary-ft-color);
  --dashboard-button-bg-color-active: var(--global-bg-color-1);
  --dashboard-button-bd-color-active: var(--global-bg-color-1);
  --dashboard-button-ft-color-active: var(--primary-ft-color);
  --dashboard-button-bs-active-before-after: var(--global-bg-color-1);
  --dashboard-button-ft-color-active-hover: var(--primary-ft-color);
  --dashboard-button-bg-color-active-hover: var(--global-bg-color-1);
  --dashboard-button-bd-color-active-hover: var(--global-bg-color-1);

  /** checkbox **/
  --checkbox-ft-size: 16px;
  --checkbox-bg-color-focus: var(--global-light-color-2);
  --checkbox-bd-color-focus: var(--global-focus-color-1);
  --checkbox-ft-color: var(--global-text-color-1);
  --checkbox-ft-color-hover: var(--global-dark-color-1);
  --checkbox-bg-color-highlight: transparent;
  --checkbox-bd-color-highlight: transparent;
  --checkbox-bg-color-icon: var(--global-light-color-2);
  --checkbox-bg-color-icon-disabled: var(--input-bg-color);
  --checkbox-ft-color-icon: var(--global-text-color-1);
  --checkbox-ft-color-icon-focus: var(--global-focus-color-1);
  --checkbox-ft-color-icon-disabled: var(--global-light-color-1);
  --checkbox-br-icon: 50px;
  --checkbox-ft-color-disabled-checkbox-icon: var(--global-light-color-1);

  /** tab group ui **/
  --tab-group-ui-bg-color-tab-container: var(--global-bg-color-1);
  --tab-group-ui-br-tab-container: var(--global-border-radius);
  --tab-group-ui-br-tab-container-active: 0;
  --tab-group-ui-bg-color-tab-container-active-before-after: transparent;
  --tab-group-ui-hg-tab-container-active-before-after: var(--menu-top-outer-radius);
  --tab-group-ui-wd-tab-container-active-before-after: calc(2 * var(--menu-top-outer-radius));
  --tab-group-ui-br-tab-container-active-before: 2500px;
  --tab-group-ui-lt-tab-container-active-before: calc(0px - calc(2 * var(--menu-top-outer-radius)));
  --tab-group-ui-bs-tab-container-active-before: var(--menu-top-outer-radius) 0 0 0 var(--global-bg-color-1);
  --tab-group-ui-br-tab-container-active-after: 2500px;
  --tab-group-ui-rt-tab-container-active-after: calc(0px - calc(2 * var(--menu-top-outer-radius)));
  --tab-group-ui-bs-tab-container-active-after: calc(0px - var(--menu-top-outer-radius)) 0 0 0 var(--global-bg-color-1);

  /** tab ui **/
  --tab-ui-bg-color-toolbar-group-center-tab-hover: #bcd5d6;
  --tab-ui-bg-toolbar-group-center-tab: var(--global-bg-color-1);
  --tab-ui-br-toolbar-group-center-tab: var(--global-border-radius);
  --tab-ui-mr-toolbar-group-center-tab: var(--menu-top-outer-radius);
  --tab-ui-ft-color-toolbar-group-center-tab-container-hover: var(--global-text-color-1);
  --tab-ui-ft-color-toolbar-group-center-item-remove: var(--global-text-color-1);
  --tab-ui-ft-color-toolbar-group-center-remove-hover: var(--primary-ft-color);
  --tab-ui-ft-color-toolbar-group-center-name: var(--global-text-color-1);
  --tab-ui-bg-color-toolbar-group-center-tab-active: var(--global-bg-color-1);
  --tab-ui-br-toolbar-group-center-tab-active: var(--global-bg-color-1);
  --tab-ui-bg-color-toolbar-group-center-tab-active-before-after: transparent;
  --tab-ui-hg-toolbar-group-center-tab-active-before-after: var(--menu-top-outer-radius);
  --tab-ui-wd-toolbar-group-center-tab-active-before-after: var(--menu-top-outer-radius);
  --tab-ui-bs-toolbar-group-center-tab-active-before: var(--menu-top-outer-radius) 0 0 0 var(--global-bg-color-1);
  --tab-ui-lt-toolbar-group-center-tab-active-before: var(--menu-top-outer-radius);
  --tab-ui-bs-toolbar-group-center-tab-active-after: calc(0px - var(--menu-top-outer-radius)) 0 0 0
    var(--global-bg-color-1);
  --tab-ui-rt-toolbar-group-center-tab-active-after: var(--menu-top-outer-radius);
  --tab-ui-ft-color-icon: var(--menu-ft-color-icon);
  --tab-ui-ft-color-icon-window: var(--menu-ft-color-icon-window);
  --tab-ui-ft-color-icon-process: var(--menu-ft-color-icon-process);
  --tab-ui-ft-color-icon-report: var(--menu-ft-color-icon-report);
  --tab-ui-ft-color-icon-form: var(--menu-ft-color-icon-form);
  --tab-ui-ft-color-icon-workflow: var(--menu-ft-color-icon-workflow);

  /** tab split view ui **/
  --tab-split-view-ui-ft-color-move-btn: var(--global-label-ft-color-1);

  /** tab split view content ui **/
  --tab-split-view-content-ui-ft-color-close: var(--global-text-color-1);

  /** fieldset **/
  --fieldset-bg-color: var(--global-light-color-2);
  --fieldset-bd-color: var(--global-light-color-1);
  --fieldset-bg-color-title: var(--global-light-color-2);
  --fieldset-bg-color-title-hover: var(--global-bg-color-1);
  --fieldset-bd-color-title: var(--global-light-color-1);
  --fieldset-bd-color-title-hover: var(--global-light-color-1);
  --fieldset-ft-color-title: var(--global-dark-color-1);
  --fieldset-ft-color-title-hover: var(--global-text-color-1);
  --fieldset-bg-color-title-btn: var(--global-bg-color-1);
  --fieldset-bd-color-title-btn: var(--global-bg-color-1);
  --fieldset-ft-color-title-btn: var(--global-dark-color-1);
  --fieldset-bg-color-title-btn-hover: var(--global-bg-color-1);
  --fieldset-bd-color-title-btn-hover: var(--global-bg-color-1);
  --fieldset-ft-color-title-btn-hover: var(--global-text-color-1);
  --fieldset-br-title-btn: var(--global-border-radius);
  --fieldset-bg-color-title-btn-icon-next-info: #ededed;
  --fieldset-bd-color-title-btn-icon-next-info: transparent;
  --fieldset-bd-color-title-btn-icon-next-info-hover: transparent;
  --fieldset-ft-color-title-btn-icon-next-info-hover: var(--primary-ft-color);
  --fieldset-br-title-btn-icon-next-info: var(--global-border-radius);

  /** tabview  **/
  --tabview-bg-color-header: transparent;
  --tabview-bg-color-title: var(--global-bg-color-1);
  --tabview-bd-color-title: var(--global-light-color-1);
  --tabview-ft-color-title: var(--global-dark-color-3);
  --tabview-br-title: var(--global-border-radius);
  --tabview-bg-color-title-hover: #dbdbdb;
  --tabview-bd-color-title-hover: #dbdbdb;
  --tabview-ft-color-title-hover: var(--global-text-color-1);
  --tabview-bg-color-title-highlighted: var(--global-light-color-2);
  --tabview-bd-color-title-highlighted: var(--global-light-color-2);
  --tabview-ft-color-title-highlighted: var(--global-text-color-1);
  --tabview-bg-color-title-highlighted-hover: var(--global-light-color-2);
  --tabview-bd-color-title-highlighted-hover: var(--global-light-color-1);
  --tabview-ft-color-title-highlighted-hover: var(--global-text-color-1);
  --tabview-ft-color-title-close: #848484;
  --tabview-ft-color-title-close-hover: var(--global-dark-color-3);
  --tabview-bg-color-panels: var(--global-light-color-2);
  --tabview-bd-color-panels: var(--global-light-color-1);
  --tabview-ft-color-panels: var(--global-text-color-1);
  --tabview-br-panels: 0 3px 3px 3px;

  /** tree **/
  --tree-bg-color: var(--window-bg-color);
  --tree-br: var(--global-border-radius);
  --tree-bg-color-p-tree: var(--global-light-color-2);
  --tree-bd-color-p-tree: var(--global-light-color-1);
  --tree-ft-color-p-tree: var(--global-dark-color-3);
  --tree-br-p-tree: 0;
  --tree-bg-color-p-tree-treenode-content: #f5f5f5;
  --tree-bg-color-p-tree-treenode-content-2: transparent;
  --tree-bd-color-p-tree-treenode-content: var(--global-light-color-1);
  --tree-ft-color-p-tree-treenode-content: var(--secondary-ft-color);
  --tree-ft-color-p-tree-treenode-content-hover: var(--secondary-ft-color-hover);
  --tree-ft-color-p-tree-treenode-content-expanded: var(--primary-ft-color);
  --tree-bg-color-p-tree-treenode-content-dragover: #eaeaea;
  --tree-ft-color-p-tree-treenode-content-dragover: var(--global-dark-color-3);
  --tree-ft-color-p-tree-treenode-children-toggler: #848484;
  --tree-bg-color-p-tree-treenode-droppoint-active: var(--global-highlight-color-1);
  --tree-bd-color-p-tree-maintenance-left-btn: #d9d9d9;
  --tree-bg-color-p-tree-maintenance-row-is-summary: var(--success-bg-color);
  --tree-bg-color-p-tree-maintenance-row-is-summary-hover: var(--success-bg-color-hover);
  --tree-ft-color-p-tree-maintenance-row-is-summary: var(--success-ft-color-alt);

  /** p-treetable **/
  --p-treetable-bg-color-hoverable-rows-tbody-tr-hover: #eaeaea;
  --p-treetable-ft-color-hoverable-rows-tbody-tr-hover: var(--global-dark-color-3);
  --p-treetable-bg-color-caption-and-summary: #f4f4f4;
  --p-treetable-bd-color-caption-and-summary: var(--global-light-color-1);
  --p-treetable-ft-color-caption-and-summary: var(--global-dark-color-3);
  --p-treetable-bg-color-thead-tr-th: #f4f4f4;
  --p-treetable-bd-color-thead-tr-th: var(--global-light-color-1);
  --p-treetable-ft-color-thead-tr-th: var(--global-dark-color-3);
  --p-treetable-bg-color-tbody-tr: var(--global-light-color-2);
  --p-treetable-bg-color-tbody-tr-highlight: var(--global-highlight-color-1);
  --p-treetable-ft-color-tbody-tr: var(--global-dark-color-3);
  --p-treetable-ft-color-tbody-tr-highlight: var(--global-light-color-2);
  --p-treetable-bg-color-tbody-tr-td: initial;
  --p-treetable-bg-color-tbody-tr-td-highlight: initial;
  --p-treetable-bd-color-tbody-tr-td: var(--global-light-color-1);
  --p-treetable-bd-color-tbody-tr-td-highlight: var(--global-light-color-1);
  --p-treetable-ft-color-tbody-tr-td-toggler: #848484;
  --p-treetable-ft-color-tbody-tr-td-toggler-highlight: var(--global-light-color-2);
  --p-treetable-ft-color-tbody-tr-highlight-td: var(--global-light-color-2);
  --p-treetable-bg-color-tbody-tr-contextmenu-selected: var(--global-highlight-color-1);
  --p-treetable-ft-color-tbody-tr-contextmenu-selected: var(--global-light-color-2);
  --p-treetable-bg-color-tbody-tr-contextmenu-td-selected: initial;
  --p-treetable-bd-color-tbody-tr-contextmenu-td-selected: var(--global-light-color-1);
  --p-treetable-ft-color-tbody-tr-contextmenu-td-toggler-selected: var(--global-light-color-2);
  --p-treetable-bg-color-tfoot-tr-td: var(--global-light-color-2);
  --p-treetable-bd-color-tfoot-tr-td: var(--global-light-color-1);
  --p-treetable-ft-color-tfoot-tr-td: var(--global-dark-color-3);
  --p-treetable-bg-color-sortable-column-hover: #e0e0e0;
  --p-treetable-bg-color-sortable-column-highlight: var(--global-highlight-color-1);
  --p-treetable-ft-color-sortable-column-hover: var(--global-dark-color-3);
  --p-treetable-ft-color-sortable-column-highlight: var(--global-light-color-2);
  --p-treetable-ft-color-sortable-column-link: #848484;
  --p-treetable-ft-color-sortable-column-link-hover: var(--global-dark-color-3);
  --p-treetable-ft-color-sortable-column-link-highlight: var(--global-light-color-2);
  --p-treetable-bg-color-column-resizer-helper: var(--global-highlight-color-1);

  /** stepper **/
  --prime-stepper-bg-color-active-item: transparent;
  --prime-stepper-bd-color-active-item: var(--primary-bd-color);
  --prime-stepper-bd-color-active-item-number: var(--global-bg-color-1);
  --prime-stepper-ft-color-item-active: var(--global-text-color-1);
  --prime-stepper-ft-color-item-number-active: var(--primary-ft-color);
  --prime-stepper-ft-color-item-icon-active: var(--global-text-color-1);
  --prime-stepper-bg-color-item-inactive: transparent;
  --prime-stepper-bd-color-item-inactive: var(--global-bg-color-1);
  --prime-stepper-bd-color-item-number-inactive: var(--global-bg-color-1);
  --prime-stepper-br-item-number-inactive: 24px;
  --prime-stepper-ft-color-item-inactive: var(--global-text-color-1);
  --prime-stepper-ft-color-item-number-inactive: var(--global-text-color-1);
  --prime-stepper-ft-color-item-icon-inactive: var(--global-text-color-1);
  --prime-stepper-bg-color-item-link: transparent;
  /* stepper document */
  --prime-stepper-doc-bd-color-item-active: var(--secondary-bd-color);
  --prime-stepper-doc-ft-color-item-active: var(--secondary-ft-color);

  /** stepper ui **/
  --stepper-ui-bd-color-steps-custom: var(--global-bg-color-1);

  /** autocomplete **/
  --autocomplete-padding: inherit;
  --autocomplete-ft-color-data-conflict: var(--global-text-color-1);
  --autocomplete-bd-color-iu-field-conflict-input: var(--alert-bd-color);
  --autocomplete-bd-color-iu-field-conflict-input-icon: var(--alert-bd-color);
  --autocomplete-ft-color-iu-field-conflict-input-icon: var(--alert-bd-color);

  /** prime autocomplete **/
  --prime-autocomplete-bg-color-more-results: #add8e680;
  --prime-autocomplete-bg-color-more-results-span: var(--info-bg-color);
  --prime-autocomplete-ft-color-more-results-span: var(--global-text-color-1);
  --prime-autocomplete-br-more-results-span: 3px;
  --prime-autocomplete-item-highlight-bg-color: #bfdffc;
  --prime-autocomplete-ft-color-icone-search: var(--global-dark-color-1);
  --prime-autocomplete-ft-color-icone-search-hover: var(--global-text-color-1);
  --prime-autocomplete-ft-color-icone-close-hover: var(--global-text-color-1);
  --prime-autocomplete-bg-color-panel: var(--global-light-color-2);
  --prime-autocomplete-bg-color-panel-list-item: #fff;
  --prime-autocomplete-bg-color-panel-list-item-highlight: #bfdffc;
  --prime-autocomplete-ft-color-panel-list-item: var(--global-dark-color-3);
  --prime-autocomplete-ft-color-panel-list-item-highlight: #000000;
  --prime-autocomplete-br-panel-list-item: 0;
  --prime-autocomplete-bg-color-panel-group: #d8dae2;
  --prime-autocomplete-ft-color-panel-group: var(--global-text-color-1);
  --prime-autocomplete-bg-color-search-panel: var(--global-bg-color-1);
  --prime-autocomplete-bg-color-search-panel-obsc: #666666b3;
  --prime-autocomplete-bg-color-search-panel-obsc-param: #666666b3;
  --prime-autocomplete-br-input: 0;
  --prime-autocomplete-bg-color-input: var(--input-bg-color);
  --prime-autocomplete-bd-color-multiple-container-hover: #212121;
  --prime-autocomplete-bd-color-multiple-container-focus: var(--global-highlight-color-1);
  --prime-autocomplete-bs-multiple-container-focus: none;
  --prime-autocomplete-ff-multiple-container-input-token-input: 'Open Sans', 'Helvetica Neue', sans-serif;
  --prime-autocomplete-bg-color-multiple-container-token: #faead6;
  --prime-autocomplete-ft-color-multiple-container-token: var(--global-text-color-1);
  --prime-autocomplete-bg-color-multiple-container-token-icon: #faead6;
  --prime-autocomplete-bg-color-iconeSearch-fa-chevron-down: var(--input-bg-color);
  --prime-autocomplete-bd-color-iconeSearch-fa-chevron-down: var(--input-bd-color);
  --prime-autocomplete-br-iconeSearch-fa-chevron-down: 0 var(--global-border-radius) var(--global-border-radius) 0;

  /** drag drop group **/
  --drag-drop-group-ft-color-shortcuts-title: var(--global-dark-color-1);
  --drag-drop-group-ft-color-shortcuts-title-hover: var(--global-text-color-1);
  --drag-drop-group-bd-color-shortcuts-title: transparent;
  --drag-drop-group-bd-color-shortcuts-title-hover: var(--global-light-color-1);
  --drag-drop-group-bd-color-shortcuts-title-active: var(--primary-bd-color);
  --drag-drop-group-bg-color-shortcuts-title-input: transparent;
  --drag-drop-group-ft-color-shortcuts-title-input: var(--global-text-color-1);
  --drag-drop-group-ft-color-shortcuts-title-active: var(--global-text-color-1);
  --drag-drop-group-bg-color-shortcuts-title-last-add: var(--global-light-color-1);
  --drag-drop-group-ft-color-shortcuts-title-last-add-icon: var(--global-light-color-2);
  --drag-drop-group-bd-color-shortcuts-title-list-hover: var(--global-light-color-2);
  --drag-drop-group-bd-color-shortcuts-title-list-active-hover: var(--global-light-color-2);
  --drag-drop-group-ft-color-shortcuts-title-list-active: #ff4100;
  --drag-drop-group-br-shortcuts-title-last-add: 50%;
  --drag-drop-group-bg-color-shortcuts-title-last-add-add-shortcut: var(--global-light-color-2);
  --drag-drop-group-br-shortcuts-title-last-add-add-shortcut: 4px;
  --drag-drop-group-bs-shortcuts-title-last-add-add-shortcut: var(--global-shadow-box);
  --drag-drop-group-bg-color-shortcuts-manager: transparent;
  --drag-drop-group-br-shortcuts-manager: 4px;
  --drag-drop-group-bs-shortcuts-manager: var(--global-shadow-box);
  --drag-drop-group-ft-color-shortcuts-manager-title: var(--global-text-color-1);
  --drag-drop-group-bd-color-shortcuts-manager-title: var(--global-light-color-1);
  --drag-drop-group-ft-color-shortcuts-manager-link: var(--global-dark-color-1);
  --drag-drop-group-ft-color-shortcuts-manager-link-hover: var(--global-dark-color-1);
  --drag-drop-group-bg-color-shortcuts-manager-link-add: var(--global-light-color-1);
  --drag-drop-group-ft-color-shortcuts-manager-link-add: var(--global-light-color-2);
  --drag-drop-group-br-shortcuts-manager-link-add: 50%;
  --drag-drop-group-br-small-shortcuts-manager-link-add: var(--global-border-radius);
  --drag-drop-group-ft-color-shortcuts-manager-link-back-icon: var(--global-text-color-1);
  --drag-drop-group-bd-color-shortcuts-manager-link-back-icon: var(--global-bg-color-1);
  --drag-drop-group-bg-color-shortcuts-manager-active: var(--global-light-color-2);
  --drag-drop-group-br-shortcuts-manager-active-opened: 4px;
  --drag-drop-group-bs-shortcuts-manager-active-opened: 0px 0px 0px 0px #00000000;
  --drag-drop-group-ft-color-shortcuts-manager-item-link-add-icon: var(--global-light-color-1);
  --drag-drop-group-ft-color-shortcuts-manager-item-link-add-icon-hover: var(--global-dark-color-2);
  --drag-drop-group-ft-color-shortcuts-manager-item-link-open-icon: var(--global-light-color-1);
  --drag-drop-group-ft-color-shortcuts-manager-item-link-open-icon-hover: var(--global-dark-color-2);
  --drag-drop-group-bg-color-box: var(--global-light-color-2);
  --drag-drop-group-br-box: var(--global-border-radius);
  --drag-drop-group-ft-color-box: var(--global-text-color-1);
  --drag-drop-group-br-box-shortcut: var(--global-border-radius);
  --drag-drop-group-bd-color-box-shortcut: var(--global-light-color-2);
  --drag-drop-group-ft-color-box-shortcut: var(--global-text-color-1);
  --drag-drop-group-bg-color-edit-list: var(--global-light-color-2);
  --drag-drop-group-br-edit-list: 4px;
  --drag-drop-group-bs-edit-list: var(--global-shadow-box);
  --drag-drop-group-ft-color-edit-list-item-link: var(--global-dark-color-1);
  --drag-drop-group-ft-color-edit-list-item-link-hover: var(--global-text-color-1);
  --drag-drop-group-ft-color-edit-list-last-item-link-hover: var(--primary-ft-color);
  --drag-drop-group-bg-color-dot-color: #333;
  --drag-drop-group-br-dot-color: 50%;
  --drag-drop-group-bd-color-dot-color-selected: var(--global-dark-color-2);
  --drag-drop-group-bg-color-mobile-menu-bar-toggle: var(--global-light-color-2);
  --drag-drop-group-br-mobile-menu-bar-toggle: var(--global-border-radius);
  --drag-drop-group-ft-color-new-menu: var(--global-dark-color-1);
  --drag-drop-group-bd-color-search-sc: var(--global-light-color-1);
  --drag-drop-group-br-search-sc-input: var(--global-border-radius);
  --drag-drop-group-bd-color-search-sc-input-focus: #67b7ff;
  --drag-drop-group-bd-color-search-sc-input-hover-icon: var(--global-dark-color-1);
  --drag-drop-group-bd-color-search-sc-input-focus-icon: #67b7ff;
  --drag-drop-group-bg-color-menu-mobile: var(--global-light-color-2);
  --drag-drop-group-bd-color-menu-mobile: #d1d1d1;
  --drag-drop-group-br-menu-mobile: var(--global-border-radius);
  --drag-drop-group-bs-menu-mobile: 0px 0px 10px 0px #0000001a;
  --drag-drop-group-bd-color-menu-mobile-show: var(--global-light-color-1);
  --drag-drop-group-bg-color-item-arrow-focused: antiquewhite;
  --drag-drop-group-ft-color-menu-icon: var(--global-dark-color-1);

  /** favorite group ui **/
  --favorite-group-ui-bg-color-empty: #e2e2e280;
  --favorite-group-ui-bd-color-empty: #c5c5c5;
  --favorite-group-ui-ft-color-empty: transparent;
  --favorite-group-ui-br-empty: 0;
  --favorite-group-ui-bs-empty: none;
  --favorite-group-ui-bs-shortcutsList-box: 0px 0px 9px 0px #0000004d;
  --favorite-group-ui-ft-color-list-shortcutsList: var(--global-text-color-1);
  --favorite-group-ui-bd-color-list-shortcutsList-item: transparent;
  --favorite-group-ui-bd-color-list-shortcutsList-item-hover: var(--global-light-color-1);
  --favorite-group-ui-bd-color-list-shortcutsList-item-active: var(--primary-bd-color);
  --favorite-group-ui-ft-color-list-shortcutsList-item-active-link: var(--global-text-color-1);
  --favorite-group-ui-ft-color-list-shortcutsList-item-link: var(--global-dark-color-1);
  --favorite-group-ui-ft-color-list-shortcutsList-item-link-hover: var(--global-text-color-1);
  --favorite-group-ui-bd-color-favoriteBorder: var(--global-light-color-2);
  --favorite-group-ui-ft-color-icon: var(--menu-ft-color-icon);
  --favorite-group-ui-ft-color-icon-window: var(--menu-ft-color-icon-window);
  --favorite-group-ui-ft-color-icon-process: var(--menu-ft-color-icon-process);
  --favorite-group-ui-ft-color-icon-report: var(--menu-ft-color-icon-report);
  --favorite-group-ui-ft-color-icon-form: var(--menu-ft-color-icon-form);
  --favorite-group-ui-ft-color-icon-workflow: var(--menu-ft-color-icon-workflow);

  /** widget group ui **/
  --widget-group-ui-bg-color-empty: #e2e2e280;
  --widget-group-ui-bd-color-empty: #c5c5c5;
  --widget-group-ui-ft-color-empty: transparent;
  --widget-group-ui-br-empty: 0;
  --widget-group-ui-bs-empty: none;

  /** list widget **/
  --list-widget-bg-color-list-data: #e7e7e7ab;
  --list-widget-ft-color-list-data: #000000;
  --list-widget-br-list-data: 4px;
  --list-widget-bs-list-data: 0 1px 2px #75757580;

  /** iupics badge **/
  --iupics-badge-br: var(--global-border-radius);
  --iupics-badge-bg-color-shift: var(--global-light-color-2);
  --iupics-badge-bd-color-shift: var(--global-text-color-1);
  --iupics-badge-ft-color-shift: var(--global-text-color-1);
  --iupics-badge-bg-color-not-shift: var(--global-text-color-1);
  --iupics-badge-bd-color-not-shift: var(--global-text-color-1);
  --iupics-badge-ft-color-not-shift: var(--global-light-color-2);
  --iupics-badge-bg-color-count: var(--primary-bg-color);
  --iupics-badge-ft-color-count: var(--global-light-color-2);
  --iupics-badge-br-count: 6px;

  /** overlay panel **/
  --overlay-panel-bg-color: var(--global-light-color-2);
  --overlay-panel-ft-color: #495057;
  --overlay-panel-bs: 0px 11px 15px -7px #00000033, 0px 24px 38px 3px #00000024, 0px 9px 46px 8px #0000001f;
  --overlay-panel-br: 3px;

  /** prime overlay **/
  --prime-overlay-left-arrow: 0;
  --prime-overlay-bd-color-panel-flipped: transparent;

  /** ui overlaypanel **/
  --ui-overlaypanel-bg-color: var(--global-light-color-2);
  --ui-overlaypanel-bd-color: var(--global-light-color-2);
  --ui-overlaypanel-ft-color: var(--global-dark-color-3);
  --ui-overlaypanel-bd-color-before-1: #c8c8c800;
  --ui-overlaypanel-bd-color-before-2: #ececec;
  --ui-overlaypanel-bd-color-after-1: #ffffff00;
  --ui-overlaypanel-bd-color-after-2: var(--global-light-color-2);
  --ui-overlaypanel-bd-color-flipped-before: var(--global-light-color-1);
  --ui-overlaypanel-bd-color-flipped-after: var(--global-light-color-2);
  --ui-overlaypanel-bg-color-close: var(--global-highlight-color-1);
  --ui-overlaypanel-bg-color-close-hover: #005b9f;
  --ui-overlaypanel-ft-color-close: var(--global-light-color-2);
  --ui-overlaypanel-ft-color-close-hover: var(--global-light-color-2);

  /** prime file upload **/
  --prime-file-upload-bg-color-title: var(--global-bg-color-1);
  --prime-file-upload-ft-color-title: var(--global-text-color-1);
  --prime-file-upload-br-title: var(--global-border-radius);
  --prime-file-upload-bg-color-file-list: var(--global-light-color-2);
  --prime-file-upload-bd-color-file-list: var(--global-bg-color-1);
  --prime-file-upload-br-file-list: var(--global-border-radius);
  --prime-file-upload-bd-color-table-col: var(--global-bg-color-1);
  --prime-file-upload-bd-color-table-last-col: var(--global-bg-color-1);
  --prime-file-upload-br-table: var(--global-border-radius);
  --prime-file-upload-bg-color-table-tagged-value: var(--global-bg-color-1);
  --prime-file-upload-bd-color-table-tagged-value: #3737a1;
  --prime-file-upload-br-table-tagged-value: var(--global-border-radius);
  --prime-file-upload-bg-color-linked-file: var(--global-light-color-2);
  --prime-file-upload-bd-color-linked-file: var(--global-bg-color-1);
  --prime-file-upload-br-linked-file: var(--global-border-radius);
  --prime-file-upload-bg-color-file-list-header: var(--global-bg-color-1);
  --prime-file-upload-br-file-list-header: var(--global-border-radius);
  --prime-file-upload-bg-color-file-list-header-col: var(--global-bg-color-1);
  --prime-file-upload-bg-color-file-upload-content: var(--global-light-color-2);
  --prime-file-upload-bd-color-file-upload-content: var(--global-bg-color-1);
  --prime-file-upload-bd-color-file-upload-content-hover: var(--global-dark-color-1);
  --prime-file-upload-ft-color-file-upload-content: var(--global-dark-color-1);
  --prime-file-upload-br-file-upload-content: var(--global-border-radius);
  --prime-file-upload-ft-color-file-upload-label-text: var(--global-text-color-1);
  --prime-file-upload-ft-color-file-upload-label-hover: var(--global-text-color-1);
  --prime-file-upload-ft-color-file-upload-label-icon: #e1e1e1;
  --prime-file-upload-ft-color-file-upload-label-icon-hover: #e1e1e1;
  --prime-file-upload-bg-color-file-upload-table-odd-row: var(--global-bg-color-1);
  --prime-file-upload-bg-color-button-bar: #f4f4f4;
  --prime-file-upload-bd-color-button-bar: var(--global-light-color-1);
  --prime-file-upload-ft-color-button-bar: var(--global-dark-color-3);
  --prime-file-upload-ft-color-file-upload-overlay-panel-close: var(--global-dark-color-2);
  --prime-file-upload-bg-color-file-upload-datatable-thead: var(--global-bg-color-1);
  --prime-file-upload-bd-color-file-upload-datatable-thead: #e9ecef;
  --prime-file-upload-ft-color-file-upload-datatable-thead: #495057;
  --prime-file-upload-bg-color-file-upload-datatable-thead-sortable-col-hover: #e9ecef;
  --prime-file-upload-ft-color-file-upload-datatable-thead-sortable-col-hover: #495057;
  --prime-file-upload-bd-color-file-upload-datatable-tbody: #e9ecef;
  --prime-file-upload-bg-color-paginator: var(--global-light-color-2);
  --prime-file-upload-bd-color-paginator: #e9ecef;
  --prime-file-upload-ft-color-paginator: #6c757d;
  --prime-file-upload-br-paginator: 3px;
  --prime-file-upload-bg-color-paginator-btn: transparent;
  --prime-file-upload-ft-color-paginator-btn: #6c757d;
  --prime-file-upload-br-paginator-btn: 3px;
  --prime-file-upload-br-paginator-btn-first: 3px;
  --prime-file-upload-bg-color-paginator-page: transparent;
  --prime-file-upload-ft-color-paginator-page: #6c757d;
  --prime-file-upload-br-paginator-page: 3px;
  --prime-file-upload-bg-color-paginator-page-highlighted: #e3f2fd;
  --prime-file-upload-bd-color-paginator-page-highlighted: #e3f2fd;
  --prime-file-upload-ft-color-paginator-page-highlighted: #495057;

  /** changlog window ui **/
  --changelog-window-ui-bd-color-header: var(--global-bg-color-1);
  --changelog-window-ui-bd-color-info: var(--global-bg-color-1);
  --changelog-window-ui-br-info: var(--global-border-radius);
  --changelog-window-ui-bd-color-table-col: var(--global-dark-color-1);
  --changelog-window-ui-bg-color-table-th: var(--global-bg-color-1);

  /** cruise situation **/
  --cruise-situation-bg-color-sidebar: var(--global-light-color-2);
  --cruise-situation-br-sidebar: 0px 10px 10px 0px;
  --cruise-situation-bs-sidebar: 0 0 15px 0 #0000004d;
  --cruise-situation-bg-color-btn: #00000000;
  --cruise-situation-bg-color-btn-hover: #00000040;
  --cruise-situation-ft-color-btn: var(--primary-ft-color);
  --cruise-situation-ft-color-btn-hover: var(--primary-ft-color-hover);
  --cruise-situation-br-btn: var(--global-border-radius);

  /** specific window ui **/
  --specific-window-ui-bg-color: var(--window-bg-color);
  --specific-window-ft-color-title: var(--global-text-color-1);
  --specific-window-ui-br: 10px;

  /** fast create invoice vendor **/
  --fast-create-invoice-vendor-ft-color-label: var(--global-label-ft-color-1);
  --fast-create-invoice-vendor-ft-color-label-total: var(--global-label-ft-color-1);
  --fast-create-invoice-vendor-bg-color-qr: initial;
  --fast-create-invoice-vendor-ft-color-qr: initial;
  --fast-create-invoice-vendor-br-qr: 3px;
  --fast-create-invoice-vendor-bg-color-qr-error: #fff5f5;
  --fast-create-invoice-vendor-bd-color-qr-error: var(--alert-bd-color);
  --fast-create-invoice-vendor-ft-color-qr-error: var(--alert-ft-color);
  --fast-create-invoice-vendor-bg-color-qr-empty: #fff5f5;
  --fast-create-invoice-vendor-bd-color-qr-empty: var(--alert-bd-color);
  --fast-create-invoice-vendor-ft-color-qr-empty: var(--alert-ft-color);
  --fast-create-invoice-vendor-bd-color-qr-empty-focus: initial;
  --fast-create-invoice-vendor-ft-color-qr-empty-focus: initial;
  --fast-create-invoice-vendor-bd-color-qr-logs: var(--global-dark-color-2);
  --fast-create-invoice-vendor-br-qr-logs: 3px;
  --fast-create-invoice-vendor-bg-color-qr-logs-error: #fff5f5;
  --fast-create-invoice-vendor-bd-color-qr-logs-error: var(--alert-bd-color);
  --fast-create-invoice-vendor-bg-color-qr-logs-success: #0080001f;
  --fast-create-invoice-vendor-bd-color-qr-logs-success: var(--success-bd-color-active);
  --fast-create-invoice-vendor-ft-color-qr-logs-item: var(--global-text-color-1);
  --fast-create-invoice-vendor-ft-color-qr-logs-error-item: var(--alert-ft-color);
  --fast-create-invoice-vendor-ft-color-qr-logs-header-warning: var(--alert-ft-color);
  --fast-create-invoice-vendor-bd-color-separator: var(--global-dark-color-2);
  --fast-create-invoice-vendor-ft-color-invoice-header: var(--ag-header-foreground-color);
  --fast-create-invoice-vendor-bg-color-invoice-header: var(--ag-header-background-color);
  --fast-create-invoice-vendor-bg-color-invoice-line: var(--ag-row-background-color);
  --fast-create-invoice-vendor-bg-color-invoice-line-even: var(--ag-odd-row-background-color);
  --fast-create-invoice-vendor-bd-color-invoice-line: var(--ag-row-border-color);
  --fast-create-invoice-vendor-bg-color-invoice-line-unsaved: var(--ag-selected-row-background-color);

  /** fast create order **/
  --fast-create-order-br: var(--global-border-radius);
  --fast-create-order-bg-color-input: var(--global-light-color-2);
  --fast-create-order-bd-color-input-hover: var(--global-dark-color-1);
  --fast-create-order-bd-color-input-focus: var(--global-focus-color-1);
  --fast-create-order-br-form: var(--global-border-radius);
  --fast-create-order-bd-color-form-row: var(--global-light-color-2);
  --fast-create-order-bg-color-form-icon: var(--global-light-color-2);
  --fast-create-order-bg-color-form-icon-hover: var(--input-bg-color-icon-hover);
  --fast-create-order-bg-color-form-left: var(--quickOrder, var(--secondary-bg-color));
  --fast-create-order-bd-color-form-left: var(--global-light-color-2);
  --fast-create-order-ft-color-form-left: var(--global-text-color-1);
  --fast-create-order-bg-color-form-right: var(--global-bg-color-1);
  --fast-create-order-bd-color-form-right: var(--global-light-color-2);
  --fast-create-order-ft-color-form-right: var(--global-text-color-1);
  --fast-create-order-bg-color-items-header: var(--ag-header-background-color);
  --fast-create-order-br-items-header: var(--global-border-radius);
  --fast-create-order-ft-color-items-header: var(--ag-header-foreground-color);
  --fast-create-order-bd-color-items: var(--ag-header-background-color);
  --fast-create-order-br-items: var(--global-border-radius);
  --fast-create-order-bd-color-items: var(--ag-row-border-color);
  --fast-create-order-bd-color-item-row: var(--global-light-color-1);
  --fast-create-order-bg-color-item-row-odd: var(--ag-odd-row-background-color);
  --fast-create-order-ft-color-items-balance: var(--secondary-ft-color);
  --fast-create-order-ft-color-settings: var(--global-text-color-1);

  /** merge tool **/
  --merge-tool-br-body: 10px;
  --merge-tool-bg-color-body: transparent;
  --merge-tool-bg-color-board: var(--global-bg-color-1);
  --merge-tool-ft-color-board: var(--global-text-color-1);
  --merge-tool-br-board: var(--global-border-radius);
  --merge-tool-bg-color-board-header: transparent;
  --merge-tool-bg-color-board-inner: #fafafa;
  --merge-tool-bd-color-board-inner: var(--global-bg-color-1);
  --merge-tool-br-board-inner: 4px;
  --merge-tool-bd-color-board-title: var(--global-light-color-1);
  --merge-tool-bg-color-board-h3-title: var(--global-bg-color-1);
  --merge-tool-bd-color-board-h3-title: var(--global-light-color-2);
  --merge-tool-ft-color-board-h3-title: var(--global-text-color-1);
  --merge-tool-bg-color-board-card: var(--global-light-color-2);
  --merge-tool-br-board-card: var(--global-border-radius);
  --merge-tool-bs-board-card: 0px 0px 10px 0px #00000066;
  --merge-tool-bg-color-selected-board-card: var(--success-bg-color);

  /** gantt **/
  --gantt-bg-color-container: var(--global-light-color-2);
  --gantt-bg-color-header: #efefef;
  --gantt-br-header-btn: 0;
  --gantt-bg-color-header-btn-enabled: #efefef;
  --gantt-bg-color-header-btn-enabled-hover: var(--global-light-color-1);
  --gantt-bg-color-header-btn-enabled-active: #b4b4b4;
  --gantt-bg-color-row-odd: var(--ag-odd-row-background-color);
  --gantt-color-day-off-gradient-1: #fff;
  --gantt-color-day-off-gradient-2: #fff;
  --gantt-color-day-off-gradient-3: #ebebeb;
  --gantt-color-day-off-gradient-4: #ebebeb;

  /** planning window ui **/
  --planning-window-ui-bg-color-gantt-task-of: #65c16f;
  --planning-window-ui-bd-color-gantt-task-of: #3c9445;
  --planning-window-ui-ft-color-gantt-task-of: #f5f5f5;
  --planning-window-ui-bg-color-gantt-task-op: #74db80;
  --planning-window-ui-bd-color-gantt-task-op: #65c16f;
  --planning-window-ui-ft-color-gantt-task-op: var(--global-text-color-1);
  --planning-window-ui-bg-color-gantt-task-sub-contract: #2898b0;
  --planning-window-ui-bd-color-gantt-task-sub-contract: #667477;
  --planning-window-ui-ft-color-gantt-task-sub-contract: #f5f5f5;

  /** pos ui **/
  --pos-ui-bg-color-btn: transparent;
  --pos-ui-bd-color-btn: transparent;
  --pos-ui-ft-color-btn: #212529;
  --pos-ui-br-btn: 0.25rem;
  --pos-ui-bg-color-btn-success: var(--global-light-color-2);
  --pos-ui-bd-color-btn-success: #28a745;
  --pos-ui-ft-color-btn-success: #28a745;
  --pos-ui-bg-color-btn-info: var(--global-light-color-2);
  --pos-ui-bd-color-btn-info: #17a2b8;
  --pos-ui-ft-color-btn-info: #17a2b8;
  --pos-ui-bg-color-total-item: var(--global-light-color-2);
  --pos-ui-bd-color-total-item: transparent;
  --pos-ui-ft-color-total-item: #28a745;
  --pos-ui-br-total-item: 0.25rem;
  --pos-ui-bg-color-scrollbar-track: #f1f1f1;
  --pos-ui-bg-color-scrollbar-thumb: #888;
  --pos-ui-bg-color-scrollbar-thumb-hover: #555;
  --pos-ui-ft-color-table: #212529;
  --pos-ui-bd-color-table-col: #dee2e6;
  --pos-ui-bd-color-table-thead: #454d55;
  --pos-ui-bd-color-table-tbody: #454d55;
  --pos-ui-bg-color-table-thead-dark: var(--global-light-color-2);
  --pos-ui-bd-color-table-thead-dark: #343a40;
  --pos-ui-ft-color-table-thead-dark: #454d55;
  --pos-ui-bg-color-table-striped-odd-row: #0000000d;
  --pos-ui-bg-color-table-dark: var(--global-light-color-2);
  --pos-ui-ft-color-table-dark: #343a40;
  --pos-ui-bd-color-table-dark-th-or-td: #454d55;
  --pos-ui-bg-color-table-dark-striped-odd-row: #0000000d;

  /** process in progress ui **/
  --process-in-progress-ui-bd-color-col: var(--primary-bd-color-hover);
  --process-in-progress-ui-ft-color-process-too-long: var(--warning-ft-color-active);
  --process-in-progress-ui-bd-color-ping-info-table: var(--primary-bd-color-hover);
  --process-in-progress-ui-bg-color-ping-info-header-column-header: var(--primary-bg-color);
  --process-in-progress-ui-ft-color-ping-info-header-column-header: var(--global-light-color-2);
  --process-in-progress-ui-bg-color-ping-info-line-even: #e5e5e5;
  --process-in-progress-ui-bd-color-ping-info-item-detail: var(--global-light-color-1);
  --process-in-progress-ui-bd-color-ping-info-detail-table-main-line: var(--info-bd-color);

  /** process ui **/
  --process-ui-ft-color-title: var(--global-text-color-1);
  --process-ui-bd-color-description: var(--global-bg-color-1);
  --process-ui-ft-color-description: var(--global-text-color-1);
  --process-ui-bg-color-container-vertical: var(--global-light-color-2);
  --process-ui-br-container-vertical: var(--global-border-radius);
  --process-ui-br-container-horizontal-main-panel: var(--global-border-radius);
  --process-ui-bd-color-panel-left: var(--global-dark-color-2);
  --process-ui-bg-color-panel-right: var(--global-light-color-2);
  --process-ui-bd-small-color-process-modal: #7e7e7e;
  --process-ui-br-small-process-modal: var(--global-border-radius);

  /** calendar ui **/
  --calendar-ui-ft-color-label: var(--global-text-color-1);
  --calendar-ui-bg-color-w-btn-icone-calendar: var(--input-bg-color);
  --calendar-ui-bd-color-w-btn-icone-calendar: var(--input-bd-color);
  --calendar-ui-ft-color-w-btn-icone-calendar: var(--global-text-color-1);
  --calendar-ui-br-w-btn-icone-calendar: var(--global-border-radius);
  --calendar-ui-bg-color-datepicker: var(--global-light-color-2);
  --calendar-ui-bd-color-datepicker: #a6a6a6;

  /** calendar view ui **/
  --calendar-view-ui-bg-color-custom-dropdown: var(--global-light-color-2);
  --calendar-view-ui-bg-color-custom-dropdown-hover: var(--global-focus-color-1);
  --calendar-view-ui-bd-color-custom-dropdown: var(--global-dark-color-1);
  --calendar-view-ui-br-custom-dropdown: var(--global-border-radius);
  --calendar-view-ui-bg-color-custom-view-box: var(--global-light-color-2);
  --calendar-view-ui-bg-color-fc-col: var(--global-bg-color-1);
  --calendar-view-ui-ft-color-fc-col-fc-day-number: var(--global-dark-color-1);
  --calendar-view-ui-bg-color-fc-popover-fc-more-popover: var(--global-light-color-2);
  --calendar-view-ui-bg-color-fc-th: var(--global-light-color-2);
  --calendar-view-ui-bd-color-fc-th: #e2e2e2;
  --calendar-view-ui-ft-color-fc-th: var(--global-dark-color-1);
  --calendar-view-ui-bg-color-fc-td: var(--global-light-color-2);
  --calendar-view-ui-bd-color-fc-td: #e2e2e2;
  --calendar-view-ui-ft-color-fc-td: var(--global-text-color-1);
  --calendar-view-ui-bg-color-fc-td-sat-sun: var(--global-bg-color-1);
  --calendar-view-ui-bd-color-fc-head-container: #e2e2e2;
  --calendar-view-ui-bg-color-fc-event: var(--global-focus-color-1);
  --calendar-view-ui-bd-color-fc-event: var(--global-focus-color-1);
  --calendar-view-ui-ft-color-fc-event: var(--global-light-color-2);
  --calendar-view-ui-ft-color-fc-ltr-basic-view-day-top-day-number: var(--global-dark-color-1);

  /** calendar toolbar ui **/
  --calendar-toolbar-ui-bg-color: var(--global-light-color-2);
  --calendar-toolbar-ui-ft-color-title: var(--global-text-color-1);
  --calendar-toolbar-ui-bg-color-p-button-ui-state-default: var(--global-bg-color-1);
  --calendar-toolbar-ui-bg-color-p-button-ui-state-default-hover: var(--global-light-color-1);
  --calendar-toolbar-ui-bd-color-p-button-ui-state-default: var(--global-bg-color-1);
  --calendar-toolbar-ui-bd-color-p-button-ui-state-default-hover: var(--global-light-color-1);

  /** chart ui **/
  --chart-ui-bg-color-chart-view: var(--global-light-color-2);
  --chart-ui-bd-color-chart-view: var(--global-bg-color-1);
  --chart-ui-bg-color-chart-view-filter-ui-list-box: var(--global-light-color-2);
  --chart-ui-bd-color-chart-view-renderer: var(--global-bg-color-1);
  --chart-ui-bd-small-color-listbox-ui-listbox-list-wrapper: var(--global-bg-color-1);

  /** kanban ui **/
  --kanban-ui-bg-color-board-title: transparent;
  --kanban-ui-ft-color-board-title: var(--global-text-color-1);
  --kanban-ui-ft-color-board-card-line-right: var(--global-text-color-1);

  /** kanban board ui **/
  --kanban-board-ui-bg-color: var(--global-bg-color-1);
  --kanban-board-ui-ft-color-label-columns: var(--global-text-color-1);
  --kanban-board-ui-bg-color-board-card: var(--global-bg-color-1);
  --kanban-board-ui-br-board-card: var(--global-border-radius);
  --kanban-board-ui-bs-board-card: 0px 0px 4px 0px #00000033;
  --kanban-board-ui-bg-color-board-card-flex: var(--global-light-color-2);
  --kanban-board-ui-ft-color-board-card-flex: var(--global-text-color-1);
  --kanban-board-ui-br-board-card-flex: 4px;
  --kanban-board-ui-bs-board-card-flex: 0px 0px 4px 0px #00000033;
  --kanban-board-ui-ft-color-board-card-line-left: var(--global-text-color-1);
  --kanban-board-ui-bg-color-board-inner: var(--global-light-color-2);
  --kanban-board-ui-br-board-inner: 4px;
  --kanban-board-ui-bd-color-board-title: var(--global-light-color-1);
  --kanban-board-ui-br-issue-count-badge: 4px;
  --kanban-board-ui-br-issue-count-badge-add-button: 4px;
  --kanban-board-ui-bd-color-over: var(--global-focus-color-1);
  --kanban-board-ui-bd-color-list-board-inner: #e5e5e5;
  --kanban-board-ui-br-list-board-inner: var(--global-border-radius);

  /** additionnal info ui **/
  --additionnal-info-ui-bg-color-scrollpanel-content: var(--global-light-color-2);
  --additionnal-info-ui-bg-color-overlay-disabled: #0000ff;
  --additionnal-info-ui-bg-color-modal-background: #00000080;
  --additionnal-info-ui-ft-color-shortcuts-title: var(--global-text-color-1);
  --additionnal-info-ui-bd-color-shortcuts-title-item: transparent;
  --additionnal-info-ui-bd-color-shortcuts-title-item-hover: var(--global-light-color-1);
  --additionnal-info-ui-ft-color-shortcuts-title-item-link: var(--global-dark-color-1);
  --additionnal-info-ui-ft-color-shortcuts-title-item-link-hover: var(--global-text-color-1);
  --additionnal-info-ui-bd-color-shortcuts-title-item-active: var(--primary-bd-color);
  --additionnal-info-ui-ft-color-shortcuts-title-item-active-link: var(--global-text-color-1);
  --additionnal-info-ui-bg-color-article-accordion: var(--global-light-color-2);
  --additionnal-info-ui-ft-color-article-accordion: initial;
  --additionnal-info-ui-br-article-accordion: var(--global-border-radius);
  --additionnal-info-ui-bg-color-article-accordion-section-target: var(--global-light-color-2);
  --additionnal-info-ui-bd-color-article-accordion-section: var(--global-dark-color-1);
  --additionnal-info-ui-bd-bottom-color-article-accordion-section: var(--global-bg-color-1);
  --additionnal-info-ui-ft-color-article-accordion-section: var(--global-label-ft-color-1);
  --additionnal-info-ui-ft-color-article-accordion-section-target: var(--global-text-color-1);
  --additionnal-info-ui-br-article-accordion-section: 3px;
  --additionnal-info-ui-bg-color-article-accordion-section-h2-target: var(--global-light-color-2);
  --additionnal-info-ui-ft-color-article-accordion-section-h2: var(--global-dark-color-1);
  --additionnal-info-ui-ft-color-article-accordion-section-h2-target: var(--global-dark-color-1);

  /** edit view ui **/
  --edit-view-ui-bg-color-comp-container: var(--global-light-color-2);
  --edit-view-ui-bg-color-scrollpanel-content: var(--global-light-color-2);
  --edit-view-ui-bg-color-overlay-disabled: #0000ff;
  --edit-view-ui-bg-color-modal-background: #00000080;
  --edit-view-ui-bg-color-tab-names-manager-item-hover-and-active-list: var(--global-light-color-2);
  --edit-view-ui-bg-color-tab-names-manager-item-hover-and-active-list-item-list: var(--global-light-color-2);
  --edit-view-ui-bg-color-tab-names-manager-item-link-hover: var(--global-bg-color-1);
  --edit-view-ui-ft-color-tab-names-manager-item-link: var(--global-text-color-1);
  --edit-view-ui-ft-color-tab-names-manager-item-link-hover: var(--global-text-color-1);
  --edit-view-ui-br-tab-names-manager-item-list: 4px;
  --edit-view-ui-bs-tab-names-manager-item-list: var(--global-shadow-box);
  --edit-view-ui-bd-color-open-icon-title: var(--global-bg-color-1);
  --edit-view-ui-bg-color-edit-view-info: var(--global-light-color-2);
  --edit-view-ui-bd-color-edit-view-info: var(--global-bg-color-1);
  --edit-view-ui-bg-color-article-accordion: #ffffff00;
  --edit-view-ui-bd-color-article-accordion: var(--global-bg-color-1);
  --edit-view-ui-ft-color-article-accordion-section-additionnal-hidden: var(--global-dark-color-1);
  --edit-view-ui-ft-color-article-accordion-section-additionnal-hidden-h2: var(--global-dark-color-1);
  --edit-view-ui-ft-color-article-accordion-section-h2-link: var(--global-dark-color-1);
  --edit-view-ui-bg-color-article-accordion-section-h2-link-before: var(--global-bg-color-1);
  --edit-view-ui-br-article-accordion-section-h2-link-before: 4px;
  --edit-view-ui-bg-color-article-accordion-section: var(--global-light-color-2);
  --edit-view-ui-ft-color-article-accordion-section: var(--global-dark-color-1);
  --edit-view-ui-bg-color-article-accordion-section-h2: var(--global-light-color-2);
  --edit-view-ui-ft-color-article-accordion-section-h2: var(--global-dark-color-1);
  --edit-view-ui-bg-color-iupics-blade-content-sidebar: #0000001a;
  --edit-view-ui-bs-iupics-blade-content-sidebar-visible-content: 0 0 9px 0 #0000004d;
  --edit-view-ui-bg-color-audits-panel: #0000001a;
  --edit-view-ui-bg-color-audits-panel-content: var(--global-light-color-2);
  --edit-view-ui-bd-color-audits-panel-content: #939191;
  --edit-view-ui-ft-color-audits-panel-content: var(--global-text-color-1);
  --edit-view-ui-br-audits-panel-content: var(--global-border-radius);
  --edit-view-ui-bs-audits-panel-content: var(--global-shadow-box);

  /** modal ui **/
  --modal-ui-bg-color-mask: #00000080;
  --modal-ui-bg-color: var(--global-light-color-2);
  --modal-ui-bd-color: #7e7e7e;
  --modal-ui-br: var(--global-border-radius);
  --modal-ui-bd-color-header: var(--global-light-color-1);
  --modal-ui-ft-color-header: var(--global-text-color-1);
  --modal-ui-ft-color-body: var(--global-text-color-1);
  --modal-ui-ft-color-footer: var(--global-text-color-1);

  /** menu bar detail ui **/
  --menu-bar-detail-ui-ft-color: var(--global-text-color-1);
  --menu-bar-detail-ui-ft-color-hover: var(--global-dark-color-1);
  --menu-bar-detail-ui-bg-color-more-action: var(--global-light-color-2);
  --menu-bar-detail-ui-bd-color-more-action: var(--global-bg-color-1);
  --menu-bar-detail-ui-bg-color-button-disabled: initial;
  --menu-bar-detail-ui-bd-color-button-disabled: initial;
  --menu-bar-detail-ui-ft-color-button-disabled: var(--button-ft-color-inactive);

  /** menu bar ui **/
  --menu-bar-ui-bg-color-more-action: var(--global-light-color-2);
  --menu-bar-ui-bd-color-more-action: var(--global-bg-color-1);
  --menu-bar-ui-bg-color-menu-bar-button-disabled: initial;
  --menu-bar-ui-bd-color-menu-bar-button-disabled: initial;
  --menu-bar-ui-ft-color-menu-bar-button-disabled: var(--button-ft-color-inactive);

  /** menu smart buttons ui **/
  --menu-smart-buttons-ui-ft-color-smart-button: var(--global-text-color-1);
  --menu-smart-buttons-ui-br-smart-button-more-item-list: 4px;
  --menu-smart-buttons-ui-bs-smart-button-more-item-list: var(--global-shadow-box);
  --menu-smart-buttons-ui-bg-color-smart-button-more-item-hover-or-active-list: var(--global-light-color-2);
  --menu-smart-buttons-ui-bg-color-smart-button-more-item-hover-or-active-list-item: var(--global-light-color-2);
  --menu-smart-buttons-ui-ft-color-smart-button-more-item: var(--global-text-color-1);
  --menu-smart-buttons-ui-ft-color-smart-button-more-item-hover: var(--global-text-color-1);
  --menu-smart-buttons-ui-ft-color-smart-button-more-item-icon-more-title-hover: var(--global-bg-color-1);

  /** smart button ui **/
  --smart-button-ui-bd-color-smartbutton: var(--global-light-color-1);
  --smart-button-ui-ft-color-smartbutton-link: var(--global-text-color-1);
  --smart-button-ui-bg-color-smartbutton-link-hover: var(--global-bg-color-1);
  --smart-button-ui-ft-color-smartbutton-link-hover: var(--global-text-color-1);
  --smart-button-ui-ft-color-smartbutton-link-active: var(--global-text-color-1);
  --smart-button-ui-bd-color-smartbutton-item: var(--global-bg-color-1);

  /** page not found **/
  --page-not-found-bg-color-main-div: var(--global-bg-color-1);
  --page-not-found-bg-color-header: var(--global-light-color-2);
  --page-not-found-bg-color-lds-spinner-div: #114c93;
  --page-not-found-br-color-lds-spinner-div: 40%;
  --page-not-found-ft-color-iupics-title: var(--primary-ft-color);
  --page-not-found-ft-color-punchline: var(--global-text-color-1);
  --page-not-found-ft-color-message: var(--global-text-color-1);

  /** recent item widget ui **/
  --recent-item-widget-ui-bd-color-scrollbar-track: var(--global-scrollbar-bg-color-track);
  --recent-item-widget-ui-bg-color-scrollbar-thumb: var(--global-scrollbar-bg-color-thumb);
  --recent-item-widget-ui-bd-color-recent-item: var(--global-bg-color-1);
  --recent-item-widget-ui-bs-recent-item-hover: var(--global-shadow-box);
  /** fav grid item widget ui **/
  --fav-grid-widget-ui-bd-color-scrollbar-track: var(--global-scrollbar-bg-color-track);
  --fav-grid-widget-ui-bg-color-scrollbar-thumb: var(--global-scrollbar-bg-color-thumb);
  --fav-grid-widget-ui-bd-color: var(--global-bg-color-1);
  --fav-grid-widget-ui-bs-hover: var(--global-shadow-box);
  /** app version panel **/
  --app-version-panel-bg-color-p-table-input: var(--global-light-color-2);
  --app-version-panel-bg-color-datatable: #f4f4f4;
  --app-version-panel-bd-color-datatable: var(--global-light-color-1);
  --app-version-panel-ft-color-datatable: var(--global-dark-color-3);
  --app-version-panel-bg-color-datatable-thead: #f4f4f4;
  --app-version-panel-bd-color-datatable-thead: var(--global-light-color-1);
  --app-version-panel-ft-color-datatable-thead: var(--global-dark-color-3);
  --app-version-panel-bg-color-datatable-tbody: initial;
  --app-version-panel-bd-color-datatable-tbody: var(--global-light-color-1);
  --app-version-panel-bg-color-datatable-tbody-row: var(--global-light-color-2);
  --app-version-panel-ft-color-datatable-tbody-row: var(--global-dark-color-3);
  --app-version-panel-bg-color-datatable-tbody-row-even: #f9f9f9;
  --app-version-panel-bg-color-datatable-tbody-row-even-highlight: var(--global-highlight-color-1);
  --app-version-panel-ft-color-datatable-tbody-row-even-highlight: var(--global-light-color-2);
  --app-version-panel-bg-color-datatable-tbody-row-highlight: var(--global-highlight-color-1);
  --app-version-panel-ft-color-datatable-tbody-row-highlight: var(--global-light-color-2);
  --app-version-panel-bg-color-datatable-tbody-row-ui-context-menu-selected: var(--global-highlight-color-1);
  --app-version-panel-ft-color-datatable-tbody-row-ui-context-menu-selected: var(--global-light-color-2);
  --app-version-panel-bg-color-datatable-tbody-row-app-version-line-replica-zero: var(--alert-bg-color);
  --app-version-panel-ft-color-datatable-tbody-row-app-version-line-replica-zero: var(--alert-ft-color);
  --app-version-panel-bg-color-datatable-tfoot: #f4f4f4;
  --app-version-panel-bd-color-datatable-tfoot: var(--global-light-color-1);
  --app-version-panel-ft-color-datatable-tfoot: var(--global-dark-color-3);
  --app-version-panel-ft-color-datatable-sortable-column: #848484;
  --app-version-panel-bg-color-datatable-sortable-column-highlighted: var(--global-highlight-color-1);
  --app-version-panel-ft-color-datatable-sortable-column-highlighted: var(--global-light-color-2);
  --app-version-panel-ft-color-datatable-sortable-column-highlighted-link: var(--global-light-color-2);
  --app-version-panel-bg-color-datatable-sortable-column-not-highlighted-hover: #e0e0e0;
  --app-version-panel-ft-color-datatable-sortable-column-not-highlighted-hover: var(--global-dark-color-3);
  --app-version-panel-ft-color-datatable-sortable-column-not-highlighted-hover-link: var(--global-dark-color-3);

  /** workspace ui **/
  --workspace-ui-bg-color-context-line: none;
  --workspace-ui-ft-color-context-line: var(--global-text-color-1);
  --workspace-ui-bg-color-context-line-selection: #ff410022;
  --workspace-ui-ft-color-context-container-copied-to-clipboard: var(--primary-ft-color);
  --workspace-ui-bd-color-header-context-container: var(--global-light-color-1);

  /** index **/
  --index-ft-color-too-long-title: var(--global-dark-color-2);
  --index-bg-color-too-long-btn: var(--warning-bg-color);
  --index-bd-color-too-long-btn: var(--warning-bd-color);
  --index-ft-color-too-long-btn: var(--global-light-color-2);
  --index-br-too-long-btn: var(--global-border-radius);

  /** email editor **/
  --email-editor-bg-color: #00000040;
  --email-editor-bg-color-content: var(--global-light-color-2);
  --email-editor-bd-small-color-content: var(--global-dark-color-1);
  --email-editor-br-small-content: 15px;
  --email-editor-bg-color-header-field: transparent;
  --email-editor-bg-color-ql-header: initial;
  --email-editor-bd-color-ql-header: initial;
  --email-editor-ft-color-ql-header: #444;
  --email-editor-ft-color-ql-header-hover: #06c;
  --email-editor-bg-color-ql-header-selected: initial;
  --email-editor-bd-color-ql-header-selected: initial;
  --email-editor-ft-color-ql-header-selected: #06c;

  /** status bar count **/
  --status-bar-count-bg-color: var(--global-light-color-2);
  --status-bar-count-bd-color: var(--global-dark-color-2);
  --status-bar-count-br: var(--global-border-radius);
  --status-bar-count-ft-color-close-btn: var(--global-dark-color-2);
  --status-bar-count-ft-color-close-btn-icon: var(--global-light-color-2);
  --status-bar-count-br-close-btn-icon: 50%;

  /** preview doc **/
  --preview-doc-ft-color-object-p-a: -webkit-link;

  /** p-card **/
  --p-card-bg-color: var(--global-light-color-2);
  --p-card-ft-color: var(--global-dark-color-3);
  --p-card-bs: 0 1px 3px 0 #00000033, 0 1px 1px 0 #00000024, 0 2px 1px -1px #0000001f;
  --p-card-br-corner-all: 0;

  /** p-picklist **/
  --p-picklist-bg-color-header: #f4f4f4;
  --p-picklist-bd-color-header: var(--global-light-color-1);
  --p-picklist-ft-color-header: var(--global-dark-color-3);
  --p-picklist-bg-color-filter-container: var(--global-light-color-2);
  --p-picklist-bd-color-filter-container: var(--global-light-color-1);
  --p-picklist-ft-color-filter-container: var(--global-dark-color-3);
  --p-picklist-ft-color-filter-container-icon: var(--global-highlight-color-1);
  --p-picklist-bg-color-list: var(--global-light-color-2);
  --p-picklist-bd-color-list: var(--global-light-color-1);
  --p-picklist-ft-color-list: var(--global-dark-color-3);
  --p-picklist-bg-color-list-item: transparent;
  --p-picklist-ft-color-list-item: var(--global-dark-color-3);
  --p-picklist-bg-color-list-item-hover: #eaeaea;
  --p-picklist-ft-color-list-item-hover: var(--global-dark-color-3);
  --p-picklist-bg-color-list-item-highlight: var(--primary-bg-color);
  --p-picklist-ft-color-list-item-highlight: var(--primary-ft-color-alt);

  /** p-toast **/
  --p-toast-bg-color-h3: transparent;
  --p-toast-bg-color-message: var(--global-light-color-2);
  --p-toast-ft-color-message: var(--global-text-color-1);
  --p-toast-br-message: var(--global-border-radius);
  --p-toast-bs-message: 0px 0px 10px 0px rgba(0, 0, 0, 0.4);
  --p-toast-bg-color-message-info: var(--global-light-color-2);
  --p-toast-ft-color-message-info: #212121;
  --p-toast-ft-color-message-icon-close: var(--global-icon-ft-color-1);
  --p-toast-bg-color-message-success: var(--success-bg-color);
  --p-toast-ft-color-message-success: var(--global-text-color-1);
  --p-toast-ft-color-message-success-close-icon: var(--global-icon-ft-color-1);
  --p-toast-bg-color-message-warn: var(--global-light-color-2);
  --p-toast-bd-color-message-warn: var(--alert-bd-color);
  --p-toast-ft-color-message-warn: var(--global-text-color-1);
  --p-toast-ft-color-message-warn-close-icon: var(--global-icon-ft-color-1);
  --p-toast-bg-color-message-error: var(--alert-bg-color);
  --p-toast-bd-color-message-error: var(--alert-bd-color);
  --p-toast-ft-color-message-error: var(--alert-ft-color-alt);
  --p-toast-ft-color-message-error-h3: var(--alert-ft-color-alt);
  --p-toast-ft-color-message-error-close-icon: var(--global-icon-ft-color-1);
  --p-toast-bd-color-quick-toast-summary: var(--success-bd-color-active);

  /** p-sidebar **/
  --p-sidebar-bg-color: var(--global-light-color-2);
  --p-sidebar-bd-color: #dedede;
  --p-sidebar-ft-color: var(--global-text-color-1);
  --p-sidebar-bs: 0 0 15px 0 #0000004d;
  --p-sidebar-bs-top-content: 0 0 15px 0 #0000004d;
  --p-sidebar-ft-color-close: var(--global-dark-color-1);
  --p-sidebar-ft-color-close-hover: #daeffc;

  /** p-tooltip **/
  --p-tooltip-bd-color-arrow: var(--global-dark-color-3);
  --p-tooltip-bg-color-text: var(--global-dark-color-2);
  --p-tooltip-ft-color-text: var(--global-light-color-2);
  --p-tooltip-bs-text: 0 0 6px 0 #00000029;

  /** prime breadcrumb **/
  --prime-breadcrumb-ft-color-active: var(--global-text-color-1);
  --prime-breadcrumb-ft-color-inactive: var(--global-dark-color-1);
  --prime-breadcrumb-ft-color-inactive-hover: var(--global-text-color-1);
  --prime-breadcrumb-ft-color-list-item-first-child-link: #848484;
  --prime-breadcrumb-ft-color-list-item-chevron: var(--global-icon-ft-color-1);
  --prime-breadcrumb-ft-color-list-item-menuitem-link: var(--global-text-color-1);
  --prime-breadcrumb-ft-color-list-item-menuitem-icon: #848484;

  /** prime chips **/
  --prime-chips-bd-color-list-p-inputtext-hover: var(--global-dark-color-1);
  --prime-chips-bd-color-list-p-inputtext-ui-state-focus: var(--global-dark-color-1);
  --prime-chips-bs-list-p-inputtext-ui-state-focus: none;
  --prime-chips-bg-color-list-p-inputtext-p-chips-token: #faead6;

  /** location panel **/
  --location-panel-bd-color-hover: var(--global-light-color-1);
  --location-panel-ft-color-icon: var(--global-dark-color-1);
  --location-panel-ft-color-icon-hover: var(--global-text-color-1);
  --location-panel-br-input: var(--global-border-radius);

  /** process logs ui **/
  --process-logs-ui-ft-color-item: var(--global-text-color-1);

  /** banner **/
  --banner-bg-color-success: var(--success-bg-color);
  --banner-ft-color-success: var(--success-ft-color-alt);
  --banner-bg-color-error: var(--alert-bg-color);
  --banner-ft-color-error: var(--alert-ft-color-alt);
  --banner-bg-color-warning: var(--warning-bg-color);
  --banner-ft-color-warning: var(--warning-ft-color-alt);
  --banner-bg-color-message: var(--info-bg-color);
  --banner-ft-color-message: var(--info-ft-color-alt);
  --banner-ft-color-hidden-message: var(--global-text-color-1);
  --banner-ft-color-text-link: var(--primary-ft-color-alt);

  /** product attribute **/
  --product-attribute-ft-color: var(--alert-ft-color);

  /** value preference panel **/
  --value-preference-panel-ft-color-help: var(--info-ft-color);

  /** grid view ui **/
  --grid-view-ui-bg-color-modal-background: #00000080;
  --grid-view-ui-bg-color-kanban-board: var(--global-light-color-2);
  --grid-view-ui-bg-color-datepicker: var(--global-light-color-2);

  /** Grid Tab **/
  --grid-tab-bg-color-column-drop-cell: var(--global-bg-color-1);
  --grid-tab-br-column-drop-cell: var(--global-border-radius);
  --grid-tab-ft-color-icon-checkbox-unchecked: var(--global-text-color-1);
  --grid-tab-bd-color-ltr-cell-focus: var(--global-light-color-1);
  --grid-tab-bg-color-tool-panel: #f5f5f5;
  --grid-tab-bd-color-tool-panel-1: var(--global-light-color-1);
  --grid-tab-bd-color-tool-panel-2: #bdc3c7;
  --grid-tab-bd-color-column-name-filter: var(--global-light-color-1);
  --grid-tab-br-column-name-filter: var(--global-border-radius);
  --grid-tab-ft-color-menu: var(--global-text-color-1);
  --grid-tab-br-menu: var(--global-border-radius);
  --grid-tab-bs-menu: var(--global-shadow-box);
  --grid-tab-bg-color-side-buttons: initial;
  --grid-tab-bg-color-footer: initial;
  --grid-header-checkbox-bg-color: initial;
  --grid-header-checkbox-ft-color: initial;
  --grid-header-checkbox-bg-color-checked: unset;
  --grid-header-checkbox-ft-color-checked: unset;
}

/* TODO - AFTER_MIGRATION_APIZ_GRID: remove mapping and modify theme in backend to use directly apiz grid variables */
[data-theme] {
  /**! NE PAS RENOMMER: AG-GRID variables !**/
  --ag-font-family: var(--font-family);
  --ag-foreground-color: var(--global-dark-color-2);
  --ag-header-background-color: #dfebeb;
  --ag-header-foreground-color: var(--global-dark-color-2);
  --ag-row-border-color: var(--global-light-color-1);
  --ag-odd-row-background-color: #f5f5f5;
  --ag-row-hover-color: #2ecee61a;
  --ag-row-bd-color-selected: var(--global-light-color-1);
  --ag-selected-row-background-color: var(--global-focus-color-1-translucid);
  --ag-secondary-border-color: var(--global-light-color-1);
  /* --ag-secondary-foreground-color: var(--global-light-color-2); */
}

[data-theme='apiz-grid-theme-base'] {
  --az-font-family: var(--font-family);
  --az-font-size: var(--font-size);
  --az-background-color: var(--ag-background-color, white);
  --az-background-alt-color: var(--global-bg-color-1);
  --az-foreground-color: var(--ag-foreground-color);
  --az-focus-color: var(--global-focus-color-1);

  /*#region Color */
  --az-secondary-foreground-color: var(--secondary-ft-color-alt);
  --az-secondary-background-color: var(--secondary-bg-color);
  --az-secondary-hover-background-color: var(--secondary-bg-color-hover);
  --az-alert-foreground-color: var(--alert-ft-color-alt);
  --az-alert-background-color: var(--alert-bg-color);
  --az-success-foreground-color: var(--success-ft-color-alt);
  --az-success-background-color: var(--success-bg-color);
  /*#endregion */

  /*#region row */
  --az-row-header-font-family: var(--font-family);
  --az-row-header-background-color: var(--ag-header-background-color);
  --az-row-header-foreground-color: var(--ag-header-foreground-color, var(--az-foreground-color));
  --az-row-background-color: var(--ag-background-color, initial);
  --az-row-foreground-color: var(--az-foreground-color);
  --az-row-border-color: var(--ag-row-border-color);
  --az-row-hover-background-color: var(--ag-row-hover-color);
  --az-row-selected-background-color: var(--ag-selected-row-background-color);
  --az-odd-row-background-color: var(--ag-odd-row-background-color);
  /*#endregion row */
  --az-scrollbar-background-color: var(--global-light-color-2);
  --az-scrollbar-thumb-color: var(--global-dark-color-2);

  --az-applied-item-background-color: var(--az-background-alt-color);
  --az-applied-item-hover-background-color: var(--ag-row-hover-color);
  --az-applied-item-close-hover-foreground-color: var(--alert-ft-color);

  --az-group-icon-foreground-color: var(--alert-ft-color);

  --az-button-pastille-font-size: 8px;
  --az-button-pastille-size: 11px;
  --az-button-pastille-background-color: var(--primary-bg-color);
  --az-button-pastille-foreground-color: var(--primary-ft-color-alt);

  --az-autocomplete-list-item-highlighted-background-color: var(
    --prime-autocomplete-bg-color-panel-list-item-highlight
  );
}

.apiz-grid-panel[data-theme='apiz-grid-theme-base'] .buttons {
  --az-button-secondary-background-color: var(--primary-bg-color);
  --az-button-secondary-hover-background-color: var(--primary-bg-color-hover);
  --az-button-secondary-foreground-color: var(--primary-ft-color-alt);

  --az-panel-button-label-font-size: 1em;

  --az-button-background-color: var(--az-secondary-background-color);
  --az-button-border-color: var(--secondary-bd-color);
  --az-button-label-foreground-color: var(--az-secondary-foreground-color);
  --az-button-hover-background-color: var(--az-secondary-hover-background-color);
  --az-button-hover-border-color: var(--secondary-bd-color-hover);
  --az-button-label-hover-foreground-color: var(--secondary-ft-color-alt-hover);
  --az-button-focus-background-color: var(--secondary-bg-color-focus);
  --az-button-focus-foreground-color: var(--secondary-ft-color-alt-focus);
  --az-button-focus-border-color: var(--secondary-bd-color-focus);
}
