@font-face {
  font-family: 'icomoon';
  src: url('fonts/icomoon.eot?18plaz');
  src:
    url('fonts/icomoon.eot?18plaz#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?18plaz') format('truetype'),
    url('fonts/icomoon.woff?18plaz') format('woff'),
    url('fonts/icomoon.svg?18plaz#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^='icon-'],
[class*=' icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-dropbox:before {
  content: '\e980';
}
.icon-github:before {
  content: '\e981';
}
.icon-google-drive:before {
  content: '\e982';
}
.icon-onedrive:before {
  content: '\e983';
}
.icon-youtube:before {
  content: '\e985';
}
.icon-notes:before {
  content: '\f27b';
}
.icon-bell-slash:before {
  content: '\e962';
}
.icon-bell:before {
  content: '\e968';
}
.icon-add-square:before {
  content: '\e967';
}
.icon-user:before {
  content: '\e960';
}
.icon-sql:before {
  content: '\e95c';
}
.icon-zip:before {
  content: '\e95d';
}
.icon-txt:before {
  content: '\e959';
}
.icon-csv:before {
  content: '\e95a';
}
.icon-html:before {
  content: '\e956';
  color: #e34f26;
}
.icon-excel:before {
  content: '\e957';
  color: #217346;
}
.icon-word:before {
  content: '\e958';
  color: #2b579a;
}
.icon-truck:before {
  content: '\e96b';
}
.icon-truck-fast:before {
  content: '\e96c';
}
.icon-warehouse:before {
  content: '\e96d';
}
.icon-clipboard:before {
  content: '\e96e';
}
.icon-snooze-zzz:before {
  content: '\e96f';
}
.icon-refresh:before {
  content: '\e97f';
}
.icon-dolly-empty:before {
  content: '\e970';
}
.icon-clipboard-check:before {
  content: '\e971';
}
.icon-clipboard-list:before {
  content: '\e972';
}
.icon-circle-check:before {
  content: '\e973';
}
.icon-triangle-exclamation:before {
  content: '\e974';
}
.icon-check:before {
  content: '\e975';
}
.icon-wrench:before {
  content: '\e976';
}
.icon-arrow-left-long:before {
  content: '\e977';
}
.icon-dolly:before {
  content: '\e978';
}
.icon-truck-ramp-box:before {
  content: '\e979';
}
.icon-print-2:before {
  content: '\e97a';
}
.icon-delete-2:before {
  content: '\e97b';
}
.icon-right-left:before {
  content: '\e97c';
}
.icon-house:before {
  content: '\e97d';
}
.icon-filter-2:before {
  content: '\e97e';
}
.icon-hashtag:before {
  content: '\e96a';
}
.icon-bookmark-o:before {
  content: '\e906';
}
.icon-bookmark:before {
  content: '\e969';
}
.icon-user-circle:before {
  content: '\e964';
}
.icon-send:before {
  content: '\e963';
}
.icon-edit-pencil:before {
  content: '\e961';
}
.icon-email:before {
  content: '\e95e';
}
.icon-phone:before {
  content: '\e95f';
}
.icon-pointer-2:before {
  content: '\e965';
}
.icon-blocked:before {
  content: '\ea0e';
}
.icon-cross:before {
  content: '\ea0f';
}
.icon-checkmark:before {
  content: '\ea10';
}
.icon-arrow-up:before {
  content: '\ea32';
}
.icon-arrow-up-right:before {
  content: '\ea33';
}
.icon-arrow-right:before {
  content: '\ea34';
}
.icon-arrow-down-right:before {
  content: '\ea35';
}
.icon-arrow-down:before {
  content: '\ea36';
}
.icon-file:before {
  content: '\e95b';
}
.icon-pdf:before {
  content: '\eadf';
}
.icon-spinner:before {
  content: '\e984';
}
.icon-today-full:before {
  content: '\e90c';
}
.icon-today:before {
  content: '\e920';
}
.icon-menu-bars:before {
  content: '\e929';
}
.icon-menu-more:before {
  content: '\e955';
}
.icon-menu-bullets:before {
  content: '\e92a';
}
.icon-info:before {
  content: '\e950';
}
.icon-folder:before {
  content: '\e94a';
}
.icon-tree:before {
  content: '\e94e';
}
.icon-group-check:before {
  content: '\e924';
}
.icon-favorite:before {
  content: '\e94d';
}
.icon-checkbox-unchecked:before {
  content: '\e944';
}
.icon-checkbox-crossed:before {
  content: '\e966';
}
.icon-checkbox-checked:before {
  content: '\e918';
}
.icon-checkbox-tri-false:before {
  content: '\e953';
}
.icon-number:before {
  content: '\e951';
}
.icon-accounting:before {
  content: '\e91d';
}
.icon-calendar:before {
  content: '\e91f';
}
.icon-filter:before {
  content: '\e921';
}
.icon-tiers:before {
  content: '\e908';
}
.icon-locked:before {
  content: '\e900';
}
.icon-pointer:before {
  content: '\e935';
}
.icon-invoice:before {
  content: '\e949';
}
.icon-img:before {
  content: '\e936';
}
.icon-hours:before {
  content: '\e905';
}
.icon-search:before {
  content: '\e931';
}
.icon-search-bolder:before {
  content: '\e930';
}
.icon-advanced-search:before {
  content: '\e926';
}
.icon-advanced-search-2:before {
  content: '\e946';
}
.icon-zoom:before {
  content: '\e927';
}
.icon-grab:before {
  content: '\e92d';
}
.icon-tri-az:before {
  content: '\e925';
}
.icon-tri-za:before {
  content: '\e922';
}
.icon-tri:before {
  content: '\e94f';
}
.icon-close:before {
  content: '\e941';
}
.icon-add:before {
  content: '\e937';
}
.icon-save3x:before {
  content: '\e92c';
}
.icon-edit:before {
  content: '\e945';
}
.icon-edit-2:before {
  content: '\e932';
}
.icon-refresh3x:before {
  content: '\e933';
}
.icon-line-loader:before {
  content: '\e923';
}
.icon-undo:before {
  content: '\e919';
}
.icon-delete:before {
  content: '\e940';
}
.icon-print:before {
  content: '\e934';
}
.icon-mail:before {
  content: '\e93a';
}
.icon-copy:before {
  content: '\e93f';
}
.icon-attach3x:before {
  content: '\e93e';
}
.icon-export:before {
  content: '\e93c';
}
.icon-download:before {
  content: '\e93d';
}
.icon-external-links:before {
  content: '\e952';
}
.icon-links:before {
  content: '\e904';
}
.icon-table:before {
  content: '\e92e';
}
.icon-kanban:before {
  content: '\e93b';
}
.icon-select:before {
  content: '\e92f';
}
.icon-up:before {
  content: '\e92b';
}
.icon-next:before {
  content: '\e942';
}
.icon-open:before {
  content: '\e938';
}
.icon-back:before {
  content: '\e943';
}
.icon-next-ancre:before {
  content: '\e939';
}
.icon-next-info:before {
  content: '\e928';
}
.icon-article:before {
  content: '\e91b';
}
.icon-order:before {
  content: '\e947';
}
.icon-delivery:before {
  content: '\e90d';
}
.icon-reception:before {
  content: '\e909';
}
.icon-expedition:before {
  content: '\e91a';
}
.icon-document:before {
  content: '\e94b';
}
.icon-edit-doc:before {
  content: '\e948';
}
.icon-bank-extract:before {
  content: '\e907';
}
.icon-notification:before {
  content: '\e94c';
}
.icon-code-branch:before {
  content: '\e917';
}
.icon-code-branch-2:before {
  content: '\e913';
}
.icon-code-branch-3:before {
  content: '\e901';
}
.icon-payment:before {
  content: '\e903';
}
.icon-window:before {
  content: '\e916';
}
.icon-window-2:before {
  content: '\e911';
}
.icon-specific-window:before {
  content: '\e914';
}
.icon-specific-window-2:before {
  content: '\e90e';
}
.icon-process-cogs:before {
  content: '\e912';
}
.icon-process-cogs-3:before {
  content: '\e90f';
}
.icon-graph-2:before {
  content: '\e91e';
}
.icon-graph-3:before {
  content: '\e902';
}
.icon-graph-smart:before {
  content: '\e90b';
}
.icon-chartline:before {
  content: '\e915';
}
.icon-param-smart:before {
  content: '\e90a';
}
.icon-activity-smart:before {
  content: '\e91c';
}
.icon-calendar-smart:before {
  content: '\e910';
}
.icon-folder-search:before {
  content: '\e954';
}
